import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import UserProfile from '../models/userProfile';
import { Store } from '@ngrx/store';
import { HealthPlazaItemsByCustomer } from '../models/health-plaza/HealthPlazaReponse';

@Injectable({
  providedIn: 'root'
})
export class ApiHealthPlazaService {
  bffBaseUrl = environment.apiBffBaseUrl;
  userId: string = null;

  constructor(
    private http: HttpClient,
    private store: Store<{ 'userProfile': UserProfile }>
  ) {
    this.store.subscribe(userProfile => {
      if (userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
   }

  public getHealthPlazaEventsOverview(): Promise<HealthPlazaItemsByCustomer[]> {
    return this.http.get<HealthPlazaItemsByCustomer[]>(`${this.bffBaseUrl}health-plaza/overview/${this.userId}`).toPromise();
  }

  public qrCodeScanned(eventId: string): Promise<{reward: number}> {
    return this.http.post<{reward: number}>(
      `${this.bffBaseUrl}health-plaza/qrCodeScanned`,
      `"${eventId}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    ).toPromise();
  }

  public linkFollowed(eventId: string): Promise<{reward: number}> {
    return this.http.post<{reward: number}>(
      `${this.bffBaseUrl}health-plaza/linkFollowed`,
      `"${eventId}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    ).toPromise();
  }
}
