<div class="container">
    <!-- This is the background image -->
    <img class="event-image-bg" src="assets/img/health-plaza/events-upcoming.jpg" alt="event" draggable="false">

    
    <div class="event-info">
        <p class="info-bar header">
            Stay tuned, more events and items coming soon!
        </p>
    </div>

    <div class="logo-container">
        <img class="logo" src="assets/img/nav-bottom-menu/icn_dashboard_active.svg" alt="event-company-logo" draggable="false">
    </div>
</div>