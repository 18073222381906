import { IFeatureFlag, FeatureFlag } from './featureFlag';

export interface IFeatureFlagProfile {
    featureFlags: IFeatureFlag[];
}

export class FeatureFlagProfile implements IFeatureFlagProfile{
    sections: string[];
    featureFlags: FeatureFlag[];
    featureUserFlags: FeatureFlag[];
    featureFlagsSections: { [key: string]: FeatureFlag[] };
    featureUserFlagsSections: { [key: string]: FeatureFlag[] };

    constructor(featureFlags: IFeatureFlag[]) {
        this.featureFlags = this.mapFeatureFlagsInterface(featureFlags);
        this.featureUserFlags = this.getFeatureUserFlags();

        this.sections = this.getSections();
        this.featureFlagsSections = this.getFeatureFlagsSections();
        this.featureUserFlagsSections = this.getFeatureUserFlagsSections();
    }

    public getSections(): string[] {
        const sections = new Set<string>();
        this.featureFlags.forEach(featureFlag => {
            sections.add(featureFlag.sectionName);
        });
        return Array.from(sections);
    }

    public getFeatureFlagsSections(): { [key: string]: FeatureFlag[] } {
        return this.featureFlagsBySection(this.featureFlags);
    }

    public getFeatureUserFlagsSections(): { [key: string]: FeatureFlag[] } {
        return this.featureFlagsBySection(this.featureUserFlags);
    }

    public getFeatureFlags(): FeatureFlag[] | null {
        return this?.featureFlags;
    }

    public getFeatureUserFlags(): FeatureFlag[] | null {
        return this?.featureFlags?.filter(featureFlag => featureFlag.allowUserOverride === true);
    }

    public getFeatureFlagBySection(sectionName: string, featureShortName: string): FeatureFlag | null {
        return this.featureFlagsSections[sectionName]?.find(feature => feature.featureShortName == featureShortName) ?? null;
    }

    public hasFeatureFlagDisabled(sectionName: string, featureShortName: string): boolean {
        return this.getFeatureFlagBySection(sectionName, featureShortName)?.isEnabled === false;
    }

    public hasFeatureFlagEnabled(sectionName: string,featureShortName: string): boolean {
        return this.getFeatureFlagBySection(sectionName, featureShortName)?.isEnabled === true;
    }

    public hasFeatureFlagDefined(sectionName: string,featureShortName: string): boolean {
        return this.getFeatureFlagBySection(sectionName, featureShortName) !== null;
    }

    private mapFeatureFlagsInterface(featureFlags: IFeatureFlag[]): FeatureFlag[] {
        return featureFlags.map(ff => new FeatureFlag(
            ff.id,
            ff.featureName,
            ff.description ?? '',
            ff.isEnabled,
            ff.defaultActivated,
            ff.allowUserOverride
        ));
    }

    private featureFlagsBySection(featureFlags: FeatureFlag[]): { [key: string]: FeatureFlag[] } {
        return featureFlags.reduce((featureFlagsSection, featureFlag) => {
            if (!featureFlagsSection[featureFlag.sectionName]) {
                featureFlagsSection[featureFlag.sectionName] = [];
            }
            featureFlagsSection[featureFlag.sectionName].push(featureFlag);
            return featureFlagsSection;
        }, {} as { [key: string]: FeatureFlag[] });
    }
}
