<div class="container-calendar">
    <select-dropdown
        class="select-dropdown"
        dropdownTitle="CALENDAR.SELECT_CALENDAR_HEADER"
        buttonText="SETTINGS.LANGUAGE_THEME.OK"
        [identifier]="identifier"
        [selectableOptions]="CALENDAR_OPTIONS"
        [selectedOption]="calendarOptionSelected"
        (onOptionSelected)="onCalendarOptionSelected($event)"
    ></select-dropdown>
    <button (click)="addToCalendar()"> 
        <img src="assets/img/icn_add_calendar.svg" alt="Add to calendar icon">
    </button>
</div>
