import {
  Component, ErrorHandler,
} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  constructor(
    router: Router,
  ) {
    this.customOfflinePage();
    this.initializeAppInsights(router);
  }

  private customOfflinePage() {
    self.addEventListener('fetch', function(event: any) {
      return event.respondWith(
        caches.match(event.request)
          .then(function(response) {
            const requestToCache = event.request.clone();

            return fetch(requestToCache).then().catch((error) => {
              console.error(error);
              // Check if the user is offline first and is trying to navigate to a web page
              if (event.request.method === 'GET' && event.request.headers.get('accept').includes('text/html')) {
                // Return the offline page
                return caches.match('/assets/offline.html');
              }
              return response;
            });
          })
      );
    });
  }

  private initializeAppInsights(router: Router) {
    if (environment.production || environment.uat) {
      const angularPlugin = new AngularPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsKey,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: {
              router: router,
              errorServices: [new ErrorHandler()],
            }
          }
        }
      });

      appInsights.loadAppInsights();
    }
  }
}
