export enum FeatureName {
    MENU_DASHBOARD = 'MENU_DASHBOARD',
    DASHBOARD_LIFESTYLE_MEASUREMENTS = 'DASHBOARD_LIFESTYLE_MEASUREMENTS',
    DASHBOARD_ACTIVATE_TILES = 'DASHBOARD_ACTIVATE_TILES',
    DASHBOARD_ACTIVATE_NO_TILES = 'DASHBOARD_ACTIVATE_NO_TILES',
    DASHBOARD_EXERCISE_MINUTES = 'DASHBOARD_EXERCISE_MINUTES',
    DASHBOARD_MEDICAL_MEASUREMENTS = 'DASHBOARD_MEDICAL_MEASUREMENTS',
    DASHBOARD_BLOOD_MEASUREMENTS = 'DASHBOARD_BLOOD_MEASUREMENTS',
    DASHBOARD_PHYSICAL_MEASUREMENTS = 'DASHBOARD_PHYSICAL_MEASUREMENTS',
    DASHBOARD_MENTAL_MEASUREMENTS = 'DASHBOARD_MENTAL_MEASUREMENTS',
    DASHBOARD_CUSTOM_MEASUREMENTS = 'DASHBOARD_CUSTOM_MEASUREMENTS',
    DASHBOARD_IMAGE_NOTES = 'DASHBOARD_IMAGE_NOTES',
    DASHBOARD_TEXT_NOTES = 'DASHBOARD_TEXT_NOTES',
    DASHBOARD_AUDIO_NOTES = 'DASHBOARD_AUDIO_NOTES',
    MENU_NETWORK = 'MENU_NETWORK',
    MENU_GAMES = 'MENU_GAMES',
    MENU_PHR = 'MENU_PHR',
    MENU_HISTORY = 'MENU_HISTORY',
    PROFILE_HEALTHCARE_PROVIDER = 'PROFILE_HEALTHCARE_PROVIDER',
    PROFILE_MEDICAL_BACKGROUND = 'PROFILE_MEDICAL_BACKGROUND',
    PROFILE_VACCINATIONS = 'PROFILE_VACCINATIONS',
    PROFILE_ALLERGIES = 'PROFILE_ALLERGIES',
    MENU_MEASUREMENTS = 'MENU_MEASUREMENTS',
    MENU_MEDICAL = 'MENU_MEDICAL',
    MENU_KNOWLEDGE_BASE = 'MENU_KNOWLEDGE_BASE',
    MENU_SETTINGS = 'MENU_SETTINGS',
    MENU_LOGOUT = 'MENU_LOGOUT',
    PROFILE_GENERAL_INFORMATION = 'PROFILE_GENERAL_INFORMATION',
    MENU_PROFILE = 'MENU_PROFILE',
    MENU_HEALTH_PLAZA = 'MENU_HEALTH_PLAZA',
}
