import {
  HttpErrorResponse,
  // HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';
import { OidcService } from '../../oidc/oidc.service';
import { apiCallFinish, apiCallStart } from '../../store/global/global.actions';
import AppState from '../../store';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NativeAppService } from './native-app.service';
import { NgxSmartModalService } from "ngx-smart-modal";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isUnauthorized: boolean = false;

  constructor(
    private oidcService: OidcService,
    private nativeAppService: NativeAppService,
    private modalService: NgxSmartModalService,
    private store: Store<AppState>) { }

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleInterceptAsPromise(originalRequest, next));
  }

  async handleInterceptAsPromise(originalRequest: HttpRequest<any>, next: HttpHandler): Promise<any> {
    this.store.dispatch(apiCallStart());

    const ignoreInterceptor = environment.routesToIgnoreInterceptor.includes(originalRequest.url);
    // console.log('ignoreInterceptor', ignoreInterceptor);

    /** Add Bearer token for secured api calls */
    let updatedRequest = originalRequest;
    // if (req.withCredentials && this.oidcService.isAuthenticated()) {

    // let isAuthenticated = false;
    let isAuthenticated = this.oidcService.isAuthenticated();

    if (!isAuthenticated && !ignoreInterceptor) {
      const refreshTokenLocalStorage = localStorage.getItem('refresh_token');
      if (refreshTokenLocalStorage) {
        await this.oidcService.refreshToken();
        isAuthenticated = this.oidcService.isAuthenticated();
      }
    }

    if (isAuthenticated && !ignoreInterceptor) {
      updatedRequest = originalRequest.clone({
        headers: originalRequest.headers.set('Authorization', 'Bearer ' + this.oidcService.getAccessToken())
      });
      // console.log(`AUTHORIZED API CALL MADE WITH CREDENTIALS: ${authReq.url}`);
    }

    // const noAuthReq = req.clone({
    //   url: `${environment.apiBaseUrl}${req.url}`,
    // });
    // console.log(`NON AUTHORIZED API CALL MADE: ${noAuthReq.url}`);
    return next.handle(updatedRequest).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          this.isUnauthorized = false;
          this.store.dispatch(apiCallFinish());
        }
        return event;
      }, (event: any) => {
        this.store.dispatch(apiCallFinish());
      }),
      catchError((exception: any) => {
        if (exception instanceof HttpErrorResponse) {
          if (exception.status === 401 || (exception.status === 400 && exception.error.error == 'invalid_grant')) {
            if (this.nativeAppService.nativeAppAvailable() && !this.isUnauthorized) {
              // this.nativeAppService.postMessageToNativeApp({
              //   action: 'unauthorized',
              //   source: 'app'
              // });

              this.isUnauthorized = true;
            } else {
              this.oidcService.silentRefreshTokenIfExpired()
                .then(() => {
                  if (!updatedRequest.url.startsWith(environment.apiMedMijUrl)) {
                    window.location.reload();
                  }
                })
                .catch(() => {
                  this.store.dispatch(apiCallFinish());
                  this.modalService.open('tokenExpiredModal');
                })
            }
          }
          if (localStorage.getItem('enableVerboseLogging') === 'true') {
            console.error(exception);
          }
        }
        this.store.dispatch(apiCallFinish());

        return throwError(exception);
      })
    )
      .toPromise();
  }
}
// export class ApiInterceptor implements HttpInterceptor {
//   constructor(private oidcService: OidcService) {
//   }
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     /** Add Bearer token for secured api calls */
//     // if (req.withCredentials && this.oidcService.isAuthenticated()) {
//     if (this.oidcService.isAuthenticated()) {
//       let cloneRequest: any;
//       if (req.url.includes('https://uat-api.selfcare4me.com/challenge-personal')) {
//         console.log('challenge-personal request', req);
//         cloneRequest = req.clone({
//           headers: req.headers.set('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjAyRjgzNDAxMzQ0QkE2NEMwMTYzRDBGRkMwOUU2MUFFNEFGQTU4Q0VSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IkF2ZzBBVFJMcGt3Qlk5RF93SjVocmtyNldNNCJ9.eyJuYmYiOjE2Mjc0NzI4MDEsImV4cCI6MTYyNzQ3NjQwMSwiaXNzIjoiaHR0cHM6Ly91YXQtc3NvLnNlbGZjYXJlNG1lLmNvbSIsImF1ZCI6WyJjaGFsbGVuZ2UucGVyc29uYWwuYXBpIiwiaHR0cHM6Ly91YXQtc3NvLnNlbGZjYXJlNG1lLmNvbS9yZXNvdXJjZXMiXSwiY2xpZW50X2lkIjoic2VsZmNhcmUtMi4wLXN3YWdnZXIiLCJzdWIiOiJmZjU1ZDMwNC04ZGY4LTRiOGYtOGY0Zi04MDE3M2Y3N2U5ZTgiLCJhdXRoX3RpbWUiOjE2MjcwMzY3NzQsImlkcCI6ImxvY2FsIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiaWdvcl93aWxsZW1zQG91dGxvb2suY29tIiwiZXh0ZXJuYWxJZCI6IjYwZWM4MzY3OGJjOWEzMDAwMTkzMDk5YiIsImp0aSI6IjU0ODMwM0FBRjlBNTU5NERENzdBOUE4NDU2QkZDODA0Iiwic2lkIjoiQzRCQTEzODQ2MDk2QjA0OUMwREIzRkE5ODc0NkJGOUIiLCJpYXQiOjE2Mjc0NzI4MDEsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJjaGFsbGVuZ2UucGVyc29uYWwuYXBpIl0sImFtciI6WyJwd2QiXX0.jWP4nmT8JHEiTiE9XDgxL_2J84oh88VxyjpC8EBq8J5MU6XQfcgWhpeUvSougeSVXcXQoh6g9GO3ToBftN0rjBln50h7ZjLakezxWeGcoJ_UOuIeIdKriFnj9TNY0oisK8jjJEZZI0pSvjWuLyfkg9Aq57Yo_T2NLllzaOJcniBBGuxr_J9iGqmbUxNU6Hz02SSUHG74Lmp6aCyhVMz24eReaS-a2EHTGAbGknKBqlBYTRx033T5sDAQVip47CHiGoKGPQkPZFb_0y3ByOpgp9y3ZLUIfknyBiteBWAydYUiEyx7_QlkJxWTy46QBgi57aqfzonfER35CATevYCOMA')
//         });
//       } else {
//         console.log('normal auth request', req);
//         cloneRequest = req.clone({
//           headers: req.headers.set('Authorization', 'Bearer ' + this.oidcService.getAccessToken())
//         });
//       }
//       // console.log(`AUTHORIZED API CALL MADE WITH CREDENTIALS: ${authReq.url}`);
//       return next.handleInterceptAsPromise(cloneRequest);
//     }
//     // const noAuthReq = req.clone({
//     //   url: `${environment.apiBaseUrl}${req.url}`,
//     // });
//     // console.log(`NON AUTHORIZED API CALL MADE: ${noAuthReq.url}`);
//     return next.handleInterceptAsPromise(req);
//   }
// }
