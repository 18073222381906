import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import AggregateDataBlock from '../../../models/enums/aggregateDataBlock';
import { MeasurementService } from '../../../measurements/measurement.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartType, TimeUnit } from 'chart.js';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NumberService } from '../../../utils/number.service';
import { ApiService } from '../../../api/api.service';
import {
  CalendarDataPoint,
  DataPoint,
  GradientDataLineGraph,
  GraphType,
  MeasurementGraphDataModel,
  TIME_PERIOD_TRANSLATION_KEYS,
  TimePeriod,
} from '../../../models/enums/GraphEnums';
import MeasurementGraphApiResult, {
  MeasurementDataPointApi,
} from '../../../models/enums/MeasurementGraphApiResult';
import {
  BaseMeasurementSettings,
  MeasurementGraphSettingLocalStorage,
  MeasurementIdEnum,
  MeasurementLimitIndication,
} from '../../../models/enums/MeasurementEnums';
import { DateService } from '../../../utils/date.service';
import { Router } from '@angular/router';
import { PremiumService } from 'src/app/shared/utils/premiumService';
import { UnitPipe } from '../../../pipes/unit.pipe';
import {
  AtalmedialClient,
  IndicationRangeModel,
} from '../../../api/api-medmij-partner.service';
import { IndicatorType } from '../../../../modules/atalmedial/test-results/line-indicator/line-indicator.component';
import { SupplierIconService } from 'src/app/shared/utils/supplier-icon.service';

const LOCAL_STORAGE_GRAPH_SETTINGS_KEY = 'graphSettings';

const TIME_PERIOD_TO_TIME_UNIT = Object.freeze({
  Day: 'hour',
  Week: 'day',
  Month: 'day',
  CalendarMonth: 'day',
  Trimester: 'week',
  Year: 'month',
  All: 'quarter',
});

let timePeriodToDisplayFormatString: {
  Day: string;
  Week: string;
  Month: string;
  CalendarMonth: string;
  Trimester: string;
  Year: string;
  All: string;
};

const defaultDataPoint: DataPoint = {
  element: undefined,
  x: new Date(),
  y: 0,
  valueFormatted: '0',
  startDateIsoString: '',
  endDate: new Date(),
  endDateIsoString: '',
  quality: MeasurementLimitIndication.MIDDLE,
  qualityColor: '#CECECE',
  totalMeasurements: 1,
  totalMeasurementsFormatted: '1',
  addedManuallyCount: 1,
  addedManuallyCountFormatted: '1',
  addedBySensorCount: 0,
  addedBySensorCountFormatted: '0',
  addedByExternalProviderCount: 0,
  addedByExternalProviderCountFormatted: '0',
  measurements: [],
  isPrecedentDataPoint: false,
  isSubsequentDataPoint: false,
};

/** This component contains the details of a certain measurement type */
@Component({
  selector: 'measurement-details',
  templateUrl: './measurement-details.component.html',
  styleUrls: ['./measurement-details.component.scss'],
  providers: [AtalmedialClient],
})

// export class MeasurementDetailsComponent implements OnInit, AfterViewInit, OnChanges {
export class MeasurementDetailsComponent
  implements OnChanges
{
  @ViewChild('tooltipBackdrop') tooltipBackdrop: ElementRef;

  @Input() measurementTypeId: MeasurementIdEnum;
  @Input() includeFloatingMenu = true;
  @Input() fixGraphOnDateTime: Date | null = null;
  @Input() customId: any = null;
  @Input() customUnit = '';
  @Input() editedMeasurementId: string;
  @Input() forUserId: string;
  @Input() forContactId: string;
  @Input() timePeriodSelected: TimePeriod = TimePeriod.DAY;
  @Input() supplier: string = null;

  //Atalmedial inputs
  @Input() atalmedialMeasurement = false;
  @Input() atalmedialResultGraphData: MeasurementGraphApiResult;
  @Input() atalmedialReferenceValues: IndicationRangeModel;
  @Input() atalmedialIcon: string = null;
  @Input() indicatorType: IndicatorType;
  @Input() isCaregiver = false;

  measurementSvg: string;
  measurementUnitString: string;
  measurementTypeTitle: string;

  readonly TimePeriods = TimePeriod;

  public chartTypeSelected: ChartType = GraphType.LINE_CHART;
  public chartOrCalendarSelected: GraphType = GraphType.LINE_CHART;
  // calendarRefreshing is a solution for calendar not resetting their state properly
  public calendarRefreshing = false;
  // 12 june - 18 june, on the top
  public timeSpanString = '';
  public latestMeasurementValue = '0';
  public latestMeasurementColor = '#333333';
  public totalAmountOfMeasurements = '0';
  // today, this week, this month
  public thisTimePeriodString = '';

  public calendarData: any;
  public chartData: MeasurementGraphDataModel | null = null;
  public measurementTypeSettings: any = null;

  public currentChartIcon = '/measurements/details/icn_line.svg';
  public dataPointsWithinXAxisTimeSpan = 0;
  modalId = 'MEASUREMENT_MODAL';
  selectedChartTypeTranslationKey = 'MEASUREMENT_DETAILS.CHARTS.LINE_GRAPH';
  selectableChartOptions = [
    {
      id: 'line',
      label: 'MEASUREMENT_DETAILS.CHARTS.LINE_GRAPH',
    },
    {
      id: 'bar',
      label: 'MEASUREMENT_DETAILS.CHARTS.BAR_CHART',
    },
  ];
  selectableChartOptionsMonth = [
    {
      id: 'line',
      label: 'MEASUREMENT_DETAILS.CHARTS.LINE_GRAPH',
    },
    {
      id: 'bar',
      label: 'MEASUREMENT_DETAILS.CHARTS.BAR_CHART',
    },
    {
      id: 'calendar',
      label: 'MEASUREMENT_DETAILS.CHARTS.CALENDAR',
    },
  ];
  aggregateData = {
    min: '0',
    max: '0',
    average: '0',
  };
  trendLineActivated = false;
  AggregateDataBlock = AggregateDataBlock;
  @Output() modalClosed = new EventEmitter();
  @Output() measurementsChangedEmitter = new EventEmitter();
  readonly timePeriodTabs = [
    { id: TimePeriod.DAY, label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.DAY' },
    { id: TimePeriod.WEEK, label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.WEEK' },
    { id: TimePeriod.MONTH, label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.MONTH' },
    {
      id: TimePeriod.TRIMESTER,
      label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.TRIMESTER',
    },
    { id: TimePeriod.YEAR, label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.YEAR' },
    { id: TimePeriod.ALL, label: 'MEASUREMENT_DETAILS.TIME_INTERVALS.ALL' },
  ];
  floatingMenuOpen = false;
  floatingMenuItems = [
    {
      id: 'MEASUREMENT_TYPE_SETTINGS',
      modalId: 'measurementSettingsPopup',
      icon: 'bttn_stepssettings.svg',
      hasBackground: true,
      positionClass: 'left',
    },
    {
      id: 'PAIR_SENSOR',
      routerLink: '/dashboard/settings/sources',
      icon: 'bttn_pairsensor.svg',
      hasBackground: true,
      positionClass: 'middle',
    },
    {
      id: 'ADD_DATA',
      modalId: 'addDataModal',
      icon: 'bttn_adddata.svg',
      hasBackground: true,
      positionClass: 'top',
    },
  ];
  legendData: { colorClass: string; label: string }[] = [];
  /** Api graph pagination vars */
  // the lowest interval loaded from the API. Note that intervals work backwards, i.e. 10 is lower than 0
  private lowestIntervalIndexLoadedFromApi = 0;
  private lowestIntervalDateLoadedFromApi: Date | null = null;
  private highestIntervalIndexLoadedFromApi = 0;
  private highestIntervalDateLoadedFromApi: Date | null = null;
  private calendarIntervalInView = 0;
  // the current position of the y axis. Changes when panning/scrolling to the left (earlier dates)
  private currentXAxisPosition = {
    xAxisTicksStartDate: new Date(),
    xAxisTicksEndDate: new Date(),
  };

  constructor(
    public modal: NgxSmartModalService,
    private premiumService: PremiumService,
    public measurementService: MeasurementService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private numberService: NumberService,
    public apiService: ApiService,
    public dateService: DateService,
    public location: Location,
    public router: Router,
    private atalmedialClient: AtalmedialClient,
    public readonly supplierIconService: SupplierIconService
  ) {
    timePeriodToDisplayFormatString =
      this.measurementService.getTimePeriodTranslatedDisplayFormatStrings();
  }

  @HostBinding('style')
  get colorLatestMeasurementColorStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--latest_measurement_color: ${this.latestMeasurementColor}`
    );
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.measurementTypeId && changes.measurementTypeId.currentValue) {
      this.measurementTypeTitle = this.customId
        ? this.customId
        : this.measurementService.getMeasurementTitleString(
            changes.measurementTypeId.currentValue
          );
      this.measurementSvg = this.measurementService.getMeasurementIcon(
        changes.measurementTypeId.currentValue
      );
      if (this.atalmedialMeasurement) {
        this.measurementSvg = this.atalmedialIcon;
      }

      this.measurementUnitString = this.customId
        ? this.customUnit
        : this.measurementService.getUnitString(
            changes.measurementTypeId.currentValue
          );
      if (
        changes.fixGraphOnDateTime &&
        changes.fixGraphOnDateTime.currentValue
      ) {
        await this.fixateGraphOnDateTime(
          changes.fixGraphOnDateTime.currentValue
        );
      } else {
        this.setGraphSettingsFromLocalStorage();
      }
    }

    if (
      changes.measurementTypeId &&
      !changes.measurementTypeId.firstChange &&
      this.modal.getModal(this.modalId)
    ) {
      if (changes.measurementTypeId.currentValue) {
        // for calendar type we want to await as there is no loading animation
        if (this.chartOrCalendarSelected === GraphType.CALENDAR) {
          if (!changes.fixGraphOnDateTime && !this.atalmedialMeasurement)
            await this.getChartData(TimePeriod.CALENDAR_MONTH, 0, null, true);
          this.modal.getModal(this.modalId).open();
        } else {
          if (!changes.fixGraphOnDateTime)
            await this.getChartData(this.timePeriodSelected, 0, null, true);
          this.modal.getModal(this.modalId).open();
        }
      } else {
        this.modal.getModal(this.modalId).close();
      }
    }

    if (changes.timePeriodSelected) {
      await this.saveGraphSettingsInLocalStorage();
    }
  }

  setEditedMeasurementId(measurementId: string): void {
    this.editedMeasurementId = measurementId;
    this.modal.open('addDataModal');
  }

  /** Proper data for the gradient colors of the lines in the line graph */
  async getGradientLineGraphData(
    measurementTypeId: string
  ): Promise<GradientDataLineGraph[]> {
    let limitValues;
    if (!this.atalmedialMeasurement) {
      this.measurementTypeSettings =
        await this.apiService.getMeasurementTypeSettings(
          measurementTypeId,
          this.forContactId,
          this.forUserId
        );
      if (
        !this.measurementTypeSettings.limitValues ||
        !this.measurementTypeSettings.limitValues.length
      ) {
        const measurementTypeSettings =
          await this.apiService.getDefaultLimitValuesForMeasurementType(
            measurementTypeId
          );
        this.measurementTypeSettings.limitValues =
          measurementTypeSettings.limitValues;
      }
      limitValues = this.measurementTypeSettings.limitValues;

      // todo: temp solution, fix after all limit values are accurate
      if (!limitValues || !limitValues.length || limitValues.length !== 5) {
        limitValues = [
          { value: 0 },
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
        ];
      }
    }

    if (this.atalmedialMeasurement && this.atalmedialReferenceValues) {
      const lowValue = this.atalmedialReferenceValues.low
        ? this.atalmedialReferenceValues.low.value
        : 0;
      const highValue = this.atalmedialReferenceValues.high
        ? this.atalmedialReferenceValues.high.value
        : this.atalmedialReferenceValues.rangeEnd;
      if (this.indicatorType === IndicatorType.BOTH) {
        limitValues = [
          { value: lowValue },
          { value: highValue },
          { value: highValue },
          { value: highValue },
          { value: lowValue },
        ];
      } else if (this.indicatorType === IndicatorType.LOW) {
        limitValues = [
          { value: lowValue },
          { value: lowValue },
          { value: highValue },
          { value: highValue },
          { value: highValue },
        ];
      } else if (this.indicatorType === IndicatorType.HIGH) {
        limitValues = [
          { value: highValue },
          { value: highValue },
          { value: lowValue },
          { value: lowValue },
          { value: lowValue },
        ];
      } else {
        limitValues = [
          { value: 0 },
          { value: 1 },
          { value: 2 },
          { value: 3 },
          { value: 4 },
        ];
      }
    }

    let qualityColors =
      this.measurementService.getGradientLineColorsBasedOnMeasurementType(
        measurementTypeId
      );
    if (this.atalmedialMeasurement && this.indicatorType !== null) {
      qualityColors =
        this.measurementService.getGradientLineColorsBasedOnIndicatorType(
          this.indicatorType
        );
    }

    const gradientData: GradientDataLineGraph[] = [];
    for (const i in qualityColors) {
      const limitValue = limitValues[i];
      const gradientDataEntry: GradientDataLineGraph = {
        value: limitValue.value,
        color: qualityColors[i].color,
      };
      gradientData.push(gradientDataEntry);
    }

    return gradientData;
  }

  /**
   * Prepare all data needed for the rendering the graph + axes + data points using the result from the API into MeasurementGraphDataModel
   * On first load determines whether we are loading additional data from the API, or it's a first time for this measurement type
   * @param {MeasurementGraphApiResult} apiGraphData
   * @param gradientDataLineGraph
   * @param {TimePeriod} timePeriod
   * @param isFirstLoad
   * @returns {MeasurementGraphDataModel}
   */
  createMeasurementGraphDataModel(
    apiGraphData: MeasurementGraphApiResult,
    gradientDataLineGraph: GradientDataLineGraph[],
    timePeriod: TimePeriod,
    isFirstLoad: boolean
  ): MeasurementGraphDataModel {
    const newDataPoints: DataPoint[] = [];

    let yAxisMinValue = Infinity;
    let yAxisMaxValue = 0;

    apiGraphData.dataPoints.forEach((dataPointApi: MeasurementDataPointApi) => {
      const dataPointDate = new Date(dataPointApi.startDateTime);

      const totalMeasurements =
        dataPointApi.addedManuallyCount +
        dataPointApi.addedBySensorCount +
        dataPointApi.addedByExternalProviderCount;

      const dataPoint: DataPoint = {
        element: undefined,
        x: dataPointDate,
        y: dataPointApi.value,
        valueFormatted: this.measurementService.formatMeasurementValue(
          this.measurementTypeId,
          dataPointApi.value
        ),
        startDateIsoString: dataPointApi.startDateTime,
        endDate: new Date(dataPointApi.endDateTime),
        endDateIsoString: dataPointApi.endDateTime,
        quality: dataPointApi.limitIndication,
        qualityColor: this.atalmedialMeasurement
          ? this.measurementService.getAtalmedialQualityColor(
              dataPointApi.limitIndication
            )
          : this.measurementService.getQualityColor(
              this.measurementTypeId,
              dataPointApi.limitIndication
            ),
        totalMeasurements: totalMeasurements,
        totalMeasurementsFormatted: this.numberService.formatNumber(
          totalMeasurements,
          0
        ),
        addedManuallyCount: dataPointApi.addedManuallyCount,
        addedManuallyCountFormatted: this.numberService.formatNumber(
          dataPointApi.addedManuallyCount,
          0
        ),
        addedBySensorCount: dataPointApi.addedBySensorCount,
        addedBySensorCountFormatted: this.numberService.formatNumber(
          dataPointApi.addedBySensorCount,
          0
        ),
        addedByExternalProviderCount: dataPointApi.addedByExternalProviderCount,
        addedByExternalProviderCountFormatted: this.numberService.formatNumber(
          dataPointApi.addedByExternalProviderCount,
          0
        ),
        measurements: dataPointApi.measurements,
      };

      newDataPoints.push(dataPoint);

      if (dataPoint.y < yAxisMinValue) yAxisMinValue = dataPoint.y;
      if (dataPoint.y > yAxisMaxValue) yAxisMaxValue = dataPoint.y;
    });

    // add the 'precedent data point' if given by API (the first measurement that is before the current range of the x-axis
    // add the 'subsequent data point' if given by API (the first measurement that is after the current range of the x-axis
    if (this.chartOrCalendarSelected === 'line') {
      if (apiGraphData.precedentDataPoint) {
        const precedentDataPointDate = new Date(
          apiGraphData.precedentDataPoint.startDateTime
        );
        // only add precedent datapoint if it's outside of already loaded range
        if (
          this.lowestIntervalDateLoadedFromApi === null ||
          precedentDataPointDate < this.lowestIntervalDateLoadedFromApi
        ) {
          const precedentDataPointRelevantData = {
            x: precedentDataPointDate,
            y: apiGraphData.precedentDataPoint.value,
            valueFormatted: this.measurementService.formatMeasurementValue(
              this.measurementTypeId,
              apiGraphData.precedentDataPoint.value
            ),
            startDateIsoString: apiGraphData.precedentDataPoint.startDateTime,
            endDate: new Date(apiGraphData.precedentDataPoint.endDateTime),
            endDateIsoString: apiGraphData.precedentDataPoint.endDateTime,
            quality: MeasurementLimitIndication.MIDDLE,
            qualityColor: this.measurementService.getQualityColor(
              this.measurementTypeId,
              MeasurementLimitIndication.MIDDLE
            ),
            isPrecedentDataPoint: true,
          };

          // merge precedent data point with unneeded data point properties default values
          apiGraphData.precedentDataPoint = {
            ...defaultDataPoint,
            ...precedentDataPointRelevantData,
          };

          newDataPoints.unshift(apiGraphData.precedentDataPoint);
        }
      }
      if (apiGraphData.subsequentDataPoint) {
        const subsequentDataPointDate = new Date(
          apiGraphData.subsequentDataPoint.startDateTime
        );
        // only add subsequent datapoint if it's outside of already loaded range
        if (
          this.highestIntervalDateLoadedFromApi === null ||
          subsequentDataPointDate > this.highestIntervalDateLoadedFromApi
        ) {
          const subsequentDataPointRelevantData = {
            x: subsequentDataPointDate,
            y: apiGraphData.subsequentDataPoint.value,
            valueFormatted: this.measurementService.formatMeasurementValue(
              this.measurementTypeId,
              apiGraphData.subsequentDataPoint.value
            ),
            startDateIsoString: apiGraphData.subsequentDataPoint.startDateTime,
            endDate: new Date(apiGraphData.subsequentDataPoint.endDateTime),
            endDateIsoString: apiGraphData.subsequentDataPoint.endDateTime,
            quality: MeasurementLimitIndication.MIDDLE,
            qualityColor: this.measurementService.getQualityColor(
              this.measurementTypeId,
              MeasurementLimitIndication.MIDDLE
            ),
            isSubsequentDataPoint: true,
          };

          // merge subsequent data point with unneeded data point properties default values
          apiGraphData.subsequentDataPoint = {
            ...defaultDataPoint,
            ...subsequentDataPointRelevantData,
          };

          newDataPoints.push(apiGraphData.subsequentDataPoint);
        }
      }
    }

    let xAxisStartDate: Date;
    let xAxisEndDate: Date;
    let xAxisTicksDates: { xAxisTicksStartDate: Date; xAxisTicksEndDate: Date };
    // On first load, we need to extend the edges of the ticks a little bit.
    if (isFirstLoad) {
      xAxisStartDate = new Date(apiGraphData.startDateTime);
      xAxisEndDate = new Date(apiGraphData.endDateTime);
      if (timePeriod === TimePeriod.CALENDAR_MONTH) {
        xAxisEndDate.setDate(xAxisEndDate.getDate() - 1);
      }
      xAxisTicksDates = this.getXAxisTicksDates(
        timePeriod,
        xAxisStartDate,
        xAxisEndDate
      );
    }
    // After a pan, we reuse the current position
    else {
      // note: clone is required!
      xAxisStartDate = this.dateService.deepCloneDate(
        this.currentXAxisPosition.xAxisTicksStartDate
      );
      xAxisEndDate = this.dateService.deepCloneDate(
        this.currentXAxisPosition.xAxisTicksEndDate
      );
      xAxisTicksDates = { ...this.currentXAxisPosition };
    }

    let dataPoints: DataPoint[];
    if (isFirstLoad || this.chartOrCalendarSelected === 'calendar') {
      dataPoints = newDataPoints;
    } else {
      const oldDataPoints = [...this.chartData.dataPoints];

      // remove the precedent and subsequent data points: they will be replaced with the re-fetch
      const previousPrecedentDataPointIndex = oldDataPoints.findIndex(
        (dataPoint) => dataPoint.isPrecedentDataPoint
      );
      if (previousPrecedentDataPointIndex !== -1) {
        oldDataPoints.splice(previousPrecedentDataPointIndex, 1);
      }
      const previousSubsequentDataPointIndex = oldDataPoints.findIndex(
        (dataPoint) => dataPoint.isSubsequentDataPoint
      );
      if (previousSubsequentDataPointIndex !== -1) {
        oldDataPoints.splice(previousSubsequentDataPointIndex, 1);
      }

      dataPoints = [...newDataPoints, ...oldDataPoints];
      dataPoints.sort(
        (dp1: DataPoint, dp2: DataPoint) => dp1.x.getTime() - dp2.x.getTime()
      );
    }

    return {
      timePeriod: timePeriod,
      xAxisStartDate: xAxisStartDate,
      xAxisEndDate: xAxisEndDate,
      yAxisMinValue: yAxisMinValue,
      yAxisMaxValue: yAxisMaxValue,
      yAxisLabel: this.measurementTypeTitle.toUpperCase(),
      dataPoints: dataPoints,
      xAxisTicksUnit: <TimeUnit>TIME_PERIOD_TO_TIME_UNIT[timePeriod],
      xAxisTicksStartDate: xAxisTicksDates.xAxisTicksStartDate,
      xAxisTicksEndDate: xAxisTicksDates.xAxisTicksEndDate,
      xAxisLabel: timePeriodToDisplayFormatString[timePeriod],
      animateDataPoints: isFirstLoad,
      gradientDataLineGraph: gradientDataLineGraph,
    };
  }

  /**
   * Gets the start and end date of the x-axis time ticks.
   * These need to be a little extended than the start and end point of the data points to nicely render the x-axis ticks.
   * @param {TimePeriod} timePeriod
   * @param {Date | null} startDate
   * @param {Date | null} endDate
   * @returns {{xAxisTicksStartDate: Date, xAxisTicksEndDate: Date}}
   */
  getXAxisTicksDates(
    timePeriod: TimePeriod,
    startDate: Date,
    endDate: Date
  ): {
    xAxisTicksStartDate: Date;
    xAxisTicksEndDate: Date;
  } {
    // note: cloning the date is necessary, the original start/endDate objects need to stay unchanged
    const tickStartDate: Date = this.dateService.deepCloneDate(startDate);
    let tickEndDate: Date = this.dateService.deepCloneDate(endDate);
    if (timePeriod === TimePeriod.WEEK) {
      tickStartDate.setHours(0);
      tickStartDate.setMinutes(0);
      tickStartDate.setHours(0);
      tickEndDate = this.dateService.deepCloneDate(tickStartDate);
      tickStartDate.setHours(tickStartDate.getHours() - 1);
      tickEndDate.setHours(tickEndDate.getHours() + 1);
      tickEndDate.setDate(tickEndDate.getDate() + 6);
    }

    return {
      xAxisTicksStartDate: tickStartDate,
      xAxisTicksEndDate: tickEndDate,
    };
  }

  /**
   * Fetch graph data from API and load graph
   * todo: comment difference intervalStartingPoint and pinPointXAxis
   * @param {TimePeriod} timePeriod
   * @param {number} intervalStartingPoint
   * @param dateToPinPointXAxis
   * @param {boolean} isFirstLoad
   * @param calendarMiddlePoint
   * @returns {Promise<void>}
   */
  async getChartData(
    timePeriod: TimePeriod,
    intervalStartingPoint: number | null,
    dateToPinPointXAxis: Date | null,
    isFirstLoad: boolean,
    calendarMiddlePoint: Date | null = null
  ): Promise<void> {
    const chartDataPromises = [];
    let atalmedialGraphData;
    if (intervalStartingPoint !== null) {
      if (!this.atalmedialMeasurement) {
        chartDataPromises.push(
          this.apiService.getMeasurementGraphData(
            this.measurementTypeId,
            timePeriod,
            intervalStartingPoint,
            this.customId,
            this.forUserId,
            this.isCaregiver,
            this.forContactId
          )
        );
      } else {
        let interval: any = await this.atalmedialClient
          .getDateRange(timePeriod, intervalStartingPoint)
          .toPromise();
        atalmedialGraphData = Object.assign({}, this.atalmedialResultGraphData);
        if (atalmedialGraphData.dataPoints) {
          atalmedialGraphData.dataPoints =
            atalmedialGraphData.dataPoints.filter(
              (dp) =>
                new Date(dp.startDateTime) >= new Date(interval.item1) &&
                new Date(dp.endDateTime) <= new Date(interval.item2)
            );
        }
        atalmedialGraphData.startDateTime = interval.item1;
        atalmedialGraphData.endDateTime = interval.item2;
      }
    } else if (dateToPinPointXAxis) {
      chartDataPromises.push(
        this.apiService.getMeasurementGraphDataByDateTime(
          this.measurementTypeId,
          timePeriod,
          dateToPinPointXAxis.toISOString(),
          this.customId,
          this.forUserId,
          this.isCaregiver,
          this.forContactId
        )
      );
    } else {
      return;
    }

    // todo: don't re-fetch measurement settings if measurement hasn't changed, or the getChartData call is not triggered from new measurement settings?
    let apiResultGraphData;

    chartDataPromises.push(
      this.getGradientLineGraphData(this.measurementTypeId)
    );

    const apiResult = await Promise.all(chartDataPromises);

    const gradientData = apiResult[apiResult.length - 1];
    if (!this.atalmedialMeasurement) {
      apiResultGraphData = apiResult[0];
    } else {
      apiResultGraphData = atalmedialGraphData;
    }

    if (
      apiResultGraphData.intervalStartingPoint ||
      (apiResultGraphData.intervalStartingPoint === 0 &&
        !this.atalmedialMeasurement)
    ) {
      intervalStartingPoint = apiResultGraphData.intervalStartingPoint;
    }

    this.chartData = this.createMeasurementGraphDataModel(
      apiResultGraphData,
      gradientData,
      timePeriod,
      isFirstLoad
    );

    this.setAggregateGraphData(
      this.chartData.xAxisStartDate,
      this.chartData.xAxisEndDate,
      timePeriod
    );

    this.legendData = this.measurementService.getQualityLegendData(
      this.measurementTypeId
    );
    if (this.atalmedialMeasurement) {
      this.legendData = this.measurementService.getAtalmedialLegendData();
    }

    // set API 'pagination' related data.
    const xAxisStartDateClone = this.dateService.deepCloneDate(
      this.chartData.xAxisStartDate
    );
    const xAxisEndDateClone = this.dateService.deepCloneDate(
      this.chartData.xAxisEndDate
    );

    if (
      this.lowestIntervalDateLoadedFromApi === null ||
      this.lowestIntervalDateLoadedFromApi > xAxisStartDateClone
    ) {
      this.lowestIntervalDateLoadedFromApi = xAxisStartDateClone;
      this.lowestIntervalIndexLoadedFromApi = intervalStartingPoint;
    }
    if (
      this.highestIntervalDateLoadedFromApi === null ||
      this.highestIntervalDateLoadedFromApi < xAxisEndDateClone
    ) {
      this.highestIntervalDateLoadedFromApi = xAxisEndDateClone;
      this.highestIntervalIndexLoadedFromApi = intervalStartingPoint;
    }

    if (isFirstLoad) {
      this.currentXAxisPosition = {
        xAxisTicksStartDate: this.dateService.deepCloneDate(
          this.chartData.xAxisTicksStartDate
        ),
        xAxisTicksEndDate: this.dateService.deepCloneDate(
          this.chartData.xAxisTicksEndDate
        ),
      };
    }

    if (
      this.chartOrCalendarSelected === GraphType.CALENDAR ||
      timePeriod === TimePeriod.CALENDAR_MONTH
    ) {
      this.loadCalendarData(
        calendarMiddlePoint ? calendarMiddlePoint : this.chartData.xAxisEndDate
      );
    }
  }

  /**
   * Called when the user has stopped panning/scrolling/swiping the graph
   * Fetches new data points if the pan is outside of the range of the currently loaded data points
   * Re-calculates aggregate data.
   * @param {{minDate: Date, maxDate: Date}} timeSpan
   * @returns {Promise<void>}
   */
  async chartPanCompletedEvent(timeSpan: {
    minDate: Date;
    maxDate: Date;
  }): Promise<void> {
    this.currentXAxisPosition = {
      xAxisTicksStartDate: timeSpan.minDate,
      xAxisTicksEndDate: timeSpan.maxDate,
    };

    // determine whether we need to fetch additional data points ('pagination')
    if (timeSpan.minDate < this.lowestIntervalDateLoadedFromApi) {
      await this.getChartData(
        this.timePeriodSelected,
        this.lowestIntervalIndexLoadedFromApi + 1,
        null,
        false
      );
    } else if (
      timeSpan.maxDate > this.highestIntervalDateLoadedFromApi &&
      this.highestIntervalIndexLoadedFromApi > 0
    ) {
      await this.getChartData(
        this.timePeriodSelected,
        this.highestIntervalIndexLoadedFromApi - 1,
        null,
        false
      );
    } else {
      this.setAggregateGraphData(
        timeSpan.minDate,
        timeSpan.maxDate,
        this.timePeriodSelected
      );
    }
  }

  /**
   * Called when the calendar is panned to next/previous month.
   * Resets the aggregate data
   * @param timeSpan
   */
  async calendarPanCompletedEvent(timeSpan: {
    start: Date;
    end: Date;
  }): Promise<void> {
    this.currentXAxisPosition = {
      xAxisTicksStartDate: timeSpan.start,
      xAxisTicksEndDate: timeSpan.end,
    };

    this.calendarIntervalInView = this.dateService.getMonthsAgoDate(
      timeSpan.start
    );

    await this.getChartData(
      TimePeriod.CALENDAR_MONTH,
      this.calendarIntervalInView,
      null,
      false
    );
  }

  /**
   * Called when switching time period by changing tabs. Reloads graph for the new time period.
   * @param {TimePeriod} newTimePeriod
   */
  async changeTimePeriodSelected(newTimePeriod: TimePeriod): Promise<void> {
    // reset graph data api fetch 'pagination' vars
    this.resetGraphDataApiPaginationVars();
    if (newTimePeriod === TimePeriod.DAY || newTimePeriod === TimePeriod.WEEK)
      await this.changeChartType(GraphType.LINE_CHART);
    await this.getChartData(
      newTimePeriod,
      this.lowestIntervalIndexLoadedFromApi,
      null,
      true
    );
    this.timePeriodSelected = newTimePeriod;

    if (newTimePeriod === TimePeriod.ALL) this.trendLineActivated = false;

    if (
      this.chartOrCalendarSelected === GraphType.CALENDAR &&
      newTimePeriod !== TimePeriod.MONTH
    ) {
      this.chartOrCalendarSelected =
        this.chartTypeSelected === GraphType.LINE_CHART
          ? GraphType.LINE_CHART
          : GraphType.BAR_CHART;
      this.chartTypeSelected = this.chartOrCalendarSelected;
      this.setChartTypeSelectedIconAndLabel(this.chartOrCalendarSelected);
    }
    this.saveGraphSettingsInLocalStorage();
  }

  /**
   * Function that sets the aggregate data relevant to the graph:
   * -latest measurement
   * -number of measurements
   * -min, avg, max aggregateBlocks
   * -timeSpanString ('12 june - 18 june' for example)
   * -thisTimePeriodString
   * @param {Date} startDate
   * @param {Date} endDate
   * @param timePeriodSelected
   */
  setAggregateGraphData(
    startDate: Date,
    endDate: Date,
    timePeriodSelected = this.timePeriodSelected
  ): void {
    const dataPoints = this.chartData.dataPoints;
    let min = null;
    let max = null;
    let sum = 0;
    let dataPointsInRange = 0;
    let measurementsInRange = 0;
    let average: number | string = 0;
    let latestMeasurement: DataPoint | null = null;

    for (const i in dataPoints) {
      const dataPoint: DataPoint = dataPoints[i];

      // only use data points that are currently in the 'scroll' view of the graph
      if (this.dateService.dateIsInRange(dataPoint.x, startDate, endDate)) {
        if (min === null || dataPoint.y < min) {
          min = dataPoint.y;
        }
        if (max === null || dataPoint.y > max) {
          max = dataPoint.y;
        }
        if (latestMeasurement === null || dataPoint.x > latestMeasurement.x) {
          latestMeasurement = dataPoint;
        }
        sum += dataPoint.y;
        dataPointsInRange++;
        measurementsInRange += dataPoint.totalMeasurements;
      }
    }
    this.dataPointsWithinXAxisTimeSpan = dataPointsInRange;

    if (dataPointsInRange) {
      min = this.measurementService.formatMeasurementValue(
        this.measurementTypeId,
        min
      );
      max = this.measurementService.formatMeasurementValue(
        this.measurementTypeId,
        max
      );
      average = this.measurementService.formatMeasurementValue(
        this.measurementTypeId,
        sum / dataPointsInRange
      );

      this.latestMeasurementValue = latestMeasurement.valueFormatted;
      this.latestMeasurementColor = this.measurementService.getQualityColor(
        this.measurementTypeId,
        latestMeasurement.quality
      );
      if (this.atalmedialMeasurement) {
        this.latestMeasurementColor =
          this.measurementService.getAtalmedialQualityColor(
            latestMeasurement.quality
          );
      }
      this.totalAmountOfMeasurements = this.numberService.formatNumber(
        measurementsInRange,
        0
      );

      this.aggregateData = {
        min,
        max,
        average,
      };
    } else {
      this.aggregateData = { min: '0', max: '0', average: '0' };
      this.latestMeasurementValue = this.translate.instant(
        'MEASUREMENT_DETAILS.NO_MEASUREMENTS'
      );
      this.totalAmountOfMeasurements = '0';
      this.latestMeasurementColor = '#333333';
    }

    this.setTimeSpanString(startDate, endDate, timePeriodSelected);
  }

  /**
   * Function that sets the timeSpanString based on the startDate, endDate and timePeriod
   * For example: '11 june 2021 – 18 june 2021'
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {TimePeriod} timePeriodSelected
   */
  setTimeSpanString(
    startDate: Date,
    endDate: Date,
    timePeriodSelected = this.timePeriodSelected
  ): void {
    // clone the dates so we can safely manipulate the date to render our timespan strings if desired

    let startDateString;
    let endDateString;

    if (timePeriodSelected === TimePeriod.DAY) {
      startDateString = startDate.toLocaleTimeString(
        this.translate.getBrowserCultureLang(),
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      );

      endDateString = endDate.toLocaleTimeString(
        this.translate.getBrowserCultureLang(),
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      );
    } else {
      startDateString = startDate.toLocaleDateString(
        this.translate.getBrowserCultureLang(),
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      );

      endDateString = endDate.toLocaleDateString(
        this.translate.getBrowserCultureLang(),
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      );
    }

    this.thisTimePeriodString =
      timePeriodSelected === TimePeriod.ALL
        ? ''
        : this.translate.instant(
            TIME_PERIOD_TRANSLATION_KEYS[timePeriodSelected]
          );
    this.timeSpanString = `${startDateString} – ${endDateString}`;
  }

  async changeChartType(
    newChartType: ChartType | string | GraphType
  ): Promise<void> {
    if (newChartType === GraphType.CALENDAR) {
      const middlePoint = this.dateService.getMidPointBetweenTwoDates(
        this.currentXAxisPosition.xAxisTicksStartDate,
        this.currentXAxisPosition.xAxisTicksEndDate
      );
      const monthsAgo = this.dateService.getMonthsAgoDate(middlePoint);
      this.calendarIntervalInView = monthsAgo;
      await this.getChartData(
        TimePeriod.CALENDAR_MONTH,
        monthsAgo,
        null,
        true,
        middlePoint
      );
    } else {
      this.chartTypeSelected = <ChartType>newChartType;
    }

    this.chartOrCalendarSelected = <GraphType>newChartType;
    this.setChartTypeSelectedIconAndLabel(newChartType);

    this.saveGraphSettingsInLocalStorage();
  }

  setChartTypeSelectedIconAndLabel(
    chartType: ChartType | GraphType | string
  ): void {
    this.selectedChartTypeTranslationKey =
      this.selectableChartOptionsMonth.find((o) => o.id === chartType).label;
    this.currentChartIcon = `/measurements/details/icn_${chartType}.svg`;
  }

  loadCalendarData(endDate: Date): void {
    const dataPoints = this.chartData.dataPoints;
    const calendarDataPoints: CalendarDataPoint[] = [];

    // load month
    const firstDayMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      1,
      0,
      0,
      0
    );
    const lastDayMonth =
      this.dateService.getLastDayOfMonthAsDate(firstDayMonth);

    this.setAggregateGraphData(
      firstDayMonth,
      lastDayMonth,
      this.timePeriodSelected
    );

    let minVal = Infinity;
    let maxVal = 0;
    let minValIndex: number | null = null;
    let maxValIndex: number | null = null;

    for (const i in dataPoints) {
      const dataPoint = dataPoints[i];
      if (
        this.dateService.dateIsInRange(dataPoint.x, firstDayMonth, lastDayMonth)
      ) {
        if (dataPoint.y < minVal) {
          minVal = dataPoint.y;
          minValIndex = parseInt(i);
        }
        if (dataPoint.y > maxVal) {
          maxVal = dataPoint.y;
          maxValIndex = parseInt(i);
        }
      }

      const calendarDataPoint: CalendarDataPoint = {
        date: dataPoint.x,
        qualityColor: dataPoint.qualityColor,
      };
      calendarDataPoints.push(calendarDataPoint);
    }

    // add 'extra text' for the calendar
    if (minValIndex !== null)
      calendarDataPoints[minValIndex].extraText =
        'min. ' +
        new UnitPipe(this.translate, this.sanitizer).transform(
          dataPoints[minValIndex].valueFormatted,
          this.measurementUnitString,
          true
        );
    if (maxValIndex !== null)
      calendarDataPoints[maxValIndex].extraText =
        'max. ' +
        new UnitPipe(this.translate, this.sanitizer).transform(
          dataPoints[maxValIndex].valueFormatted,
          this.measurementUnitString,
          true
        );

    this.calendarData = {
      calendarDataPoints: calendarDataPoints,
      startDate: firstDayMonth,
      endDate: lastDayMonth,
    };
  }

  /**
   * Called when a calendar day that contains measurements was clicked
   * Navigates to the day TimePeriod of said day and loads day data from API
   * @param {{start: Date, end: Date}} event
   */
  async onCalendarDayClicked(event: { startDate: Date }): Promise<void> {
    this.resetGraphDataApiPaginationVars();

    const startDate = this.dateService.setDateToMiddleOfDay(event.startDate);
    this.timePeriodSelected = TimePeriod.WEEK;
    await this.getChartData(this.timePeriodSelected, null, startDate, true);
    const previousChartTypeSelected = <string>this.chartTypeSelected;
    this.chartOrCalendarSelected = <GraphType>previousChartTypeSelected;
    this.setChartTypeSelectedIconAndLabel(previousChartTypeSelected);
    this.saveGraphSettingsInLocalStorage();
  }

  beforeModalClose(): void {
    if (this.chartOrCalendarSelected === GraphType.CALENDAR) {
      this.chartOrCalendarSelected = GraphType.LINE_CHART;
      this.calendarRefreshing = true;
    }
    this.resetMeasurementDetailsData();
  }

  modalCloseFinished(): void {
    if (this.calendarRefreshing) {
      // this forces ngOnDestroy to be called for calendar
      this.calendarRefreshing = false;
      this.chartOrCalendarSelected = GraphType.CALENDAR;
    }
    this.modalClosed.emit();
  }

  resetMeasurementDetailsData(): void {
    this.chartData = null;
    this.calendarData = null;
    this.measurementTypeSettings = null;
    this.fixGraphOnDateTime = null;
    this.resetGraphDataApiPaginationVars();
  }

  toggleFloatingMenu(): void {
    this.floatingMenuOpen = !this.floatingMenuOpen;
  }

  /**
   * Saves the 'graph settings' in localStorage
   */
  saveGraphSettingsInLocalStorage(): void {
    const graphSettingsLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_GRAPH_SETTINGS_KEY
    );

    if (!graphSettingsLocalStorage) {
      this.saveDefaultGraphSettingsInLocalStorage();
      return;
    }
    const graphSettings = JSON.parse(graphSettingsLocalStorage);

    graphSettings[this.measurementTypeId] = {
      timePeriodSelected: this.timePeriodSelected,
      chartTypeSelected: this.chartTypeSelected,
      chartOrCalendarSelected: this.chartOrCalendarSelected,
      trendLineActivated: this.trendLineActivated,
    };

    localStorage.setItem(
      LOCAL_STORAGE_GRAPH_SETTINGS_KEY,
      JSON.stringify(graphSettings)
    );
  }

  saveDefaultGraphSettingsInLocalStorage(): void {
    const allMeasurementTypes = Object.keys(MeasurementIdEnum).concat(
      this.measurementService.customMeasurementTypes.map(
        (customType) => customType.customName
      )
    );
    const timePeriodSelected: TimePeriod = this.timePeriodSelected;
    const chartTypeSelected: ChartType = GraphType.LINE_CHART;
    const chartOrCalendarSelected: GraphType = GraphType.LINE_CHART;
    const trendLineActivated = false;

    const baseMeasurementSetting: MeasurementGraphSettingLocalStorage = {
      timePeriodSelected,
      chartTypeSelected,
      chartOrCalendarSelected,
      trendLineActivated,
    };

    const baseMeasurementSettings: any = {};

    allMeasurementTypes.forEach((measurementType: string) => {
      baseMeasurementSettings[measurementType] = baseMeasurementSetting;
    });

    localStorage.setItem(
      'graphSettings',
      JSON.stringify(baseMeasurementSettings)
    );
  }

  /**
   * Fetches and applies the graph settings from localStorage to this component
   */
  setGraphSettingsFromLocalStorage(): void {
    if (!this.measurementTypeId) {
      return;
    }

    const graphSettingsLocalStorage: string = localStorage.getItem(
      LOCAL_STORAGE_GRAPH_SETTINGS_KEY
    );
    if (graphSettingsLocalStorage) {
      const graphSettings: any = JSON.parse(graphSettingsLocalStorage);

      let graphSettingMeasurement: MeasurementGraphSettingLocalStorage =
        graphSettings[this.measurementTypeId];
      if (!graphSettingMeasurement) {
        graphSettings[this.measurementTypeId] = new BaseMeasurementSettings();
        graphSettingMeasurement = graphSettings[this.measurementTypeId];
        localStorage.setItem(
          LOCAL_STORAGE_GRAPH_SETTINGS_KEY,
          JSON.stringify(graphSettings)
        );
      }
      this.timePeriodSelected = graphSettingMeasurement.timePeriodSelected;
      this.chartTypeSelected = graphSettingMeasurement.chartTypeSelected;
      this.chartOrCalendarSelected =
        graphSettingMeasurement.chartOrCalendarSelected;
      this.trendLineActivated = graphSettingMeasurement.trendLineActivated;
      this.setChartTypeSelectedIconAndLabel(
        graphSettingMeasurement.chartOrCalendarSelected
      );
    }
  }

  /**
   * Background backdrop effect when tooltip is opened
   * @param {boolean} event
   */
  toggleTooltipBackdrop(event: boolean): void {
    if (this.tooltipBackdrop && this.tooltipBackdrop.nativeElement) {
      this.tooltipBackdrop.nativeElement.style.opacity = event ? '0.6' : '0';
    }
  }

  /**
   * Reload graph after a new measurement was (manually) added
   * Set timespan to week and fix x-axis timespan so the newly added data point is nicely in view
   * @param measurement
   */
  async onMeasurementSaved(measurement: any): Promise<void> {
    await this.fixateGraphOnDateTime(measurement.date);
    this.measurementsChangedEmitter.emit();
  }

  async fixateGraphOnDateTime(date: Date): Promise<void> {
    if (this.chartOrCalendarSelected !== GraphType.CALENDAR) {
      this.timePeriodSelected = TimePeriod.WEEK;
    }

    this.resetGraphDataApiPaginationVars();
    await this.getChartData(this.timePeriodSelected, null, date, true);
  }

  /**
   * Reset the pagination state of which API intervals are loaded
   */
  resetGraphDataApiPaginationVars(): void {
    this.lowestIntervalIndexLoadedFromApi = 0;
    this.lowestIntervalDateLoadedFromApi = null;
    this.highestIntervalIndexLoadedFromApi = 0;
    this.highestIntervalDateLoadedFromApi = null;
    this.calendarIntervalInView = 0;
  }

  /**
   * Generate random data points
   * TODO: move somewhere more appropriate
   */
  // getChartData(): void {
  //   let dataPoints: any = [];
  //   const amountOfDataPoints = this.numberService.getRandomIntBetween(100, 200);
  //   let minY = null;
  //   let maxY = null;
  //   let averageY = 0;
  //   let minX = null;
  //   let maxX = null;
  //   const addMethods = ['MANUALLY','WEARABLE','HEALTHCARE_PROVIDER'];
  //   const notesDummy = [
  //     null,
  //     'Heavy practice session',
  //     'I was tired.',
  //     'Great weather, enjoyed it.',
  //     'Wish I would have been faster.',
  //     'Did not like the rain.',
  //     'It was kind of cold.',
  //   ];
  //   const dummyImages = [
  //     null,
  //     'https://daka.xcdn.nl/RM380,380/-/cm/Home/2021/April/BLOKJETENNIS.jpg',
  //     'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUKwJ0kbfwXXVhcNFJh3KVdFrzZPvhrq28Vg&usqp=CAU',
  //     'https://www.ctvnews.ca/polopoly_fs/1.4755447.1578349737!/httpImage/image.jpg_gen/derivatives/landscape_1020/image.jpg',
  //     'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSilrW4vFZHFTLq7msoA5L6vShG3otYX5pfUw&usqp=CAU',
  //     'https://lede-admin.coloradosun.com/wp-content/uploads/sites/15/2019/04/04-30-2019-Climbing-grief001_4.jpg',
  //   ];
  //   const moods = [...MOODS];
  //   moods.push(null);
  //
  //   for (let i = 0; i < amountOfDataPoints; i++) {
  //     const dataPointValue = this.numberService.getRandomIntBetween(1, 900);
  //
  //     const dataPoint = {
  //       id: this.numberService.getRandomIntBetween(0, Number.MAX_SAFE_INTEGER),
  //       x: new Date(this.numberService.getRandomIntBetween(1990, 2021), this.numberService.getRandomIntBetween(0, 11), this.numberService.getRandomIntBetween(1, 28), this.numberService.getRandomIntBetween(0, 23), this.numberService.getRandomIntBetween(0, 59)),
  //       y: dataPointValue,
  //       addedBy: addMethods[this.numberService.getRandomIntBetween(0, addMethods.length - 1)],
  //       quality: this.measurementService.getQualityByDataPointValue(dataPointValue, this.measurementTypeId),
  //       note: notesDummy[this.numberService.getRandomIntBetween(0, notesDummy.length - 1)], // note optional
  //       mood: moods[this.numberService.getRandomIntBetween(0, moods.length - 1)], // note optional
  //       noteImage: dummyImages[this.numberService.getRandomIntBetween(0, dummyImages.length - 1)], // note optional
  //     };
  //     dataPoints.push(dataPoint);
  //   }
  //
  //   // sort by date ASC
  //   dataPoints = dataPoints.sort((a: any, b: any) => a.x - b.x);
  //   for (let i = 0; i < dataPoints.length; i++) {
  //     const dataPoint = dataPoints[i];
  //     // add a 'bias' to test trendline: the later (in time) the measurement, the higher the value should be on average
  //     dataPoint.y = dataPoint.y + (i);
  //
  //     if (minY === null || dataPoint.y < minY) {
  //       minY = dataPoint.y;
  //     }
  //     if (maxY === null || dataPoint.y > maxY) {
  //       maxY = dataPoint.y;
  //     }
  //     averageY += dataPoint.y;
  //
  //     if (minX === null || dataPoint.x < minX) {
  //       minX = dataPoint.x;
  //     }
  //     if (maxX === null || dataPoint.x > maxX) {
  //       maxX = dataPoint.x;
  //     }
  //   }
  //
  //   // console.log('dataPoints (original)', dataPoints);
  //
  //   averageY = Math.round(averageY / amountOfDataPoints);
  //
  //   this.aggregateData = {
  //     min: minY,
  //     max: maxY,
  //     average: averageY
  //   };
  //
  //   this.chartData = {
  //     dataPoints,
  //     minY,
  //     averageY,
  //     maxY,
  //     minX,
  //     maxX,
  //   };
  //
  //   // console.log('this.chartData', this.chartData);
  // }
}
