<ngx-smart-modal [identifier]="modalKey" customClass="include-padding small-modal" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="health-plaza-reward-popup-wrapper">
    <div (click)="closeModal()" class="close-button" inlineSVG="icn_close.svg"></div>
    <div *ngIf="!alreadyHandled">
      <h3>{{ (errorOccurred ? 'HEALTH_PLAZA_REWARD_MODAL.SOMETHING_WENT_WRONG.TITLE' : getTitle()) | translate }}</h3>
      <p>{{( errorOccurred ? 'HEALTH_PLAZA_REWARD_MODAL.SOMETHING_WENT_WRONG.DESCRIPTION' : getDescription()) | translate }}</p>
      <p *ngIf="reward > 0"  class="reward-amount"><img class="fitcoin-logo" src="assets/img/fitcoin_logo.png" alt="Fitcoin logo"> {{reward}}</p>
      <div class="buttons-wrapper">
        <app-button 
          (click)="closeModal()" 
          [buttonText]="(errorOccurred ? 'HEALTH_PLAZA_REWARD_MODAL.SOMETHING_WENT_WRONG.CLOSE' : 'HEALTH_PLAZA_REWARD_MODAL.AWESOME') | translate"
          ></app-button>
      </div>
    </div>
    <div *ngIf="alreadyHandled">
      <h3>{{ 'HEALTH_PLAZA_REWARD_MODAL.ALREADY_CLAIMED.TITLE' | translate }}</h3>
      <p>{{'HEALTH_PLAZA_REWARD_MODAL.ALREADY_CLAIMED.DESCRIPTION' | translate }}</p>
      <div class="buttons-wrapper">
        <app-button 
          (click)="closeModal()" 
          [buttonText]="(errorOccurred ? 'HEALTH_PLAZA_REWARD_MODAL.SOMETHING_WENT_WRONG.CLOSE' : 'HEALTH_PLAZA_REWARD_MODAL.AWESOME') | translate"
          ></app-button>
      </div>
    </div>
  </div>
</ngx-smart-modal>
  