import { Component, effect, Input, OnInit } from '@angular/core';
import { SelectableOption } from '../select-dropdown/select-dropdown.component';
import { TranslateService } from '@ngx-translate/core';

enum CalendarType {
  APPLE = 'apple',
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
}

export interface CalendarInfo {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  location: string;
}


@Component({
  selector: 'add-to-calendar-dropdown',
  templateUrl: './add-to-calendar-dropdown.component.html',
  styleUrl: './add-to-calendar-dropdown.component.scss'
})
export class AddToCalendarDropdownComponent implements OnInit{
  readonly CALENDAR_OPTIONS: SelectableOption[] = [
    // Fix: Apple calendar is not working in the native app, you can uncomment this option when their is time to fix this in the native app
    // {id: CalendarType.APPLE, label: this.translate.instant('CALENDAR.CALENDAR_TYPE.APPLE')},
    {id: CalendarType.GOOGLE, label: this.translate.instant('CALENDAR.CALENDAR_TYPE.GOOGLE')},
    {id: CalendarType.OUTLOOK, label: this.translate.instant('CALENDAR.CALENDAR_TYPE.OUTLOOK')},
  ];

  @Input({required: true}) identifier: string;
  @Input({required: true}) calendarItem: CalendarInfo;

  calendarOptionSelected: SelectableOption;

  constructor(private readonly translate: TranslateService,) {}

  ngOnInit(): void {
    const activeCalendarOption = localStorage.getItem('calendarOptionSelected');
    if(activeCalendarOption) {
      this.calendarOptionSelected = this.CALENDAR_OPTIONS.find(option => option.id === activeCalendarOption);
    } else {
      this.calendarOptionSelected = this.CALENDAR_OPTIONS[0];
      localStorage.setItem('calendarOptionSelected', this.calendarOptionSelected.id);
    }
  }

  onCalendarOptionSelected(option: SelectableOption, ) {
      this.calendarOptionSelected = option;
      localStorage.setItem('calendarOptionSelected', option.id);

      switch(option.id) {
        case CalendarType.APPLE:
          this.downloadICS();
          break;
        case CalendarType.GOOGLE:
          this.addToGoogleCalendar();
          break;
          case CalendarType.OUTLOOK:
          this.addToOutlookCalendar();
          break;
        default:
          break;
      }
  }

  addToCalendar() {
    this.onCalendarOptionSelected(this.calendarOptionSelected);
  }

  downloadICS() {
    const event = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//Your Company//Your Product//EN',
      'BEGIN:VEVENT',
      `SUMMARY:${this.escapeICSText(this.calendarItem.title)}`,
      `DTSTART:${this.formatDateForICS(this.calendarItem.startDate)}`,
      `DTEND:${this.formatDateForICS(this.calendarItem.endDate)}`,
      `LOCATION:${this.escapeICSText(this.calendarItem.location)}`,
      `DESCRIPTION:${this.escapeICSText(this.calendarItem.description)}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\r\n');

    const blob = new Blob([event], { type: 'text/calendar;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    // Create an <a> element and trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'event.ics';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the object URL to clean up memory
    setTimeout(() => URL.revokeObjectURL(url), 1000);
  }

  // Google Calendar URL
  addToGoogleCalendar() {
    const start = this.formatDate(this.calendarItem.startDate);
    const end = this.formatDate(this.calendarItem.endDate);

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(this.calendarItem.title)}&dates=${start}/${end}&details=${encodeURIComponent(this.calendarItem.description)}&location=${encodeURIComponent(this.calendarItem.location)}`;

    window.open(url, '_blank');
  }

  // Outlook Calendar URL
  addToOutlookCalendar() {
    const start = new Date(this.calendarItem.startDate).toISOString();
    const end = new Date(this.calendarItem.endDate).toISOString();

    const url = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(this.calendarItem.title)}&startdt=${start}&enddt=${end}&body=${encodeURIComponent(this.calendarItem.description)}&location=${encodeURIComponent(this.calendarItem.location)}`;

    window.open(url, '_blank');
  }

  formatDateForICS(date: Date): string {
    return date
      .toISOString()
      .replace(/[-:]/g, '')  // Remove dashes and colons
      .replace(/\.\d{3}/g, '') // Remove milliseconds
      .slice(0, 15) + 'Z';
  }

  // Helper function to escape special characters in text fields
  escapeICSText(text: string): string {
    if (!text) return '';
    return text
      .replace(/[\\;,]/g, (match) => '\\' + match) // Escape \, ;, and ,
      .replace(/\n/g, '\\n')                       // Replace newlines with \n
      .replace(/\r/g, '\\r');                      // Replace carriage returns with \r
  }


  // Helper function to format date for Google Calendar
  formatDate(date: Date): string {
    return new Date(date).toISOString().replace(/-|:|\.\d+/g, ''); // YYYYMMDDTHHmmSSZ
  }
}
