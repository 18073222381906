import { Component } from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {BUTTON_STYLES} from '../../button/button.component';
import {OidcService} from '../../../../oidc/oidc.service';

@Component({
  selector: 'token-expired-modal',
  templateUrl: './token-expired-modal.component.html',
  styleUrls: ['./token-expired-modal.component.scss']
})
export class TokenExpiredModalComponent {

  BUTTON_STYLES = BUTTON_STYLES;

  constructor(public modal: NgxSmartModalService,
              private readonly oidService: OidcService) { }

  async closeExpireModal() {
    this.modal.close('tokenExpiredModal');
    await this.oidService.logout();
  }
}
