// todo split imports into separate modules for lazy loading
// todo split imports into separate modules for lazy loading
// todo split imports into separate modules for lazy loading
// todo split imports into separate modules for lazy loading
// todo split imports into separate modules for lazy loading

import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {AppComponent} from './app.component';
// todo enable env and service worker soon again
// import { ServiceWorkerModule } from '@angular/service-worker';
import {environment} from '../environments/environment';
// import { StoreChatModule } from './store/chat/chat.module';
import {ActionReducer, MetaReducer, Store, StoreModule} from '@ngrx/store';
// todo: enable store dev tools for non (real) production builds
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {RouteGuardService} from './routes/route.guard.service';
import {LoginGuardService} from './routes/login.guard.service';
import {ApiInterceptor} from './shared/api/api.interceptor';
import {translateHttpLoaderFactory} from './shared/api/api-translate.service';
import {authConfig} from './oidc/oidc-config';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {StoreGlobalModule} from './store/global/global.module';

import {ResourceClient} from './shared/api/api-medmij-2020-01.service';
import {medMijApiUrl, MedMijAuthenticationClient} from './shared/api/api-medmij-afsprakenstelsel.service';
import {MedMijInterceptor} from './shared/api/med-mij-interceptor.service';
import {SharedModule} from './shared/shared.module';
import {PremiumGuardService} from './routes/premium.guard.service';
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js';
import {RoutingModule} from './routing.module';
import {PartnerRouteGuard} from './routes/partner-route.guard';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {FeatureFlagsService} from './shared/utils/feature-flags.service';
import {AdminGuard} from './routes/admin.guard';
import {EventQueueService} from './shared/utils/event-queue.service';
import {AtalmedialGuard} from './routes/atalmedial.guard';
import {HealthInterceptor} from './shared/api/health-interceptor.service';
import {SettingsService} from './shared/api/settings.service';

import {userProfileReducer} from './store/userProfile/userProfile.reducer';
import {localStorageSync} from 'ngrx-store-localstorage';
import {storeDevToolsImport} from './store/store-dev-tools-import';
import UserProfile from './shared/models/userProfile';
import {take} from 'rxjs/operators';

import localeNl from '@angular/common/locales/nl'; // 🇳🇱 Dutch locale
import localeFr from '@angular/common/locales/fr'; // 🇫🇷 French locale

// Register locales
registerLocaleData(localeNl);
registerLocaleData(localeFr);

export let errorHandlerFactory = () => !environment.production && !environment.uat ? new ErrorHandler() : new ApplicationinsightsAngularpluginErrorService();

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['userProfile'],
    rehydrate: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

const AVAILABLE_LANGUAGES = ['en', 'nl', 'fr'];

/** APP INITIALIZER */
export function appInitializerFactory(translate: TranslateService, settingsService: SettingsService, injector: Injector, store: Store<{
  'userProfile': UserProfile;
}>) {
  return () => new Promise<any>((resolve: any) => {
    (window as any).store = getState(store); //make store accessible for native app

    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.addLangs(AVAILABLE_LANGUAGES);
      translate.setDefaultLang('en');

      let browserLang = translate.getBrowserLang();

      const langToSet = AVAILABLE_LANGUAGES.includes(browserLang) ? browserLang : 'en';
      translate.use(langToSet).subscribe(() => {
        // console.log(`Successfully initialized the '${langToSet}' language.`);
      }, err => {

      }, () => {
        resolve(null);
      });

      translate.use(langToSet);
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // todo enable service worker soon
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: (environment.production || environment.uat)}),
    StoreModule.forRoot({userProfile: userProfileReducer}, {metaReducers}),
    // StoreChatModule,
    StoreGlobalModule,
    storeDevToolsImport,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpBackend],
        useFactory: translateHttpLoaderFactory
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    InlineSVGModule.forRoot({baseUrl: '/assets/img/', bypassHttpClientInterceptorChain: true}),
    NgxSmartModalModule.forRoot(),
    OAuthModule.forRoot(),
    RoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: medMijApiUrl,
      useValue: environment.apiMedMijUrl
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    RouteGuardService,
    LoginGuardService,
    PremiumGuardService,
    PartnerRouteGuard,
    AdminGuard,
    AtalmedialGuard,
    MedMijAuthenticationClient,
    ResourceClient,
    FeatureFlagsService,
    EventQueueService,
    {provide: OAuthStorage, useFactory: storageFactory},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MedMijInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HealthInterceptor, multi: true},
    {provide: AuthConfig, useValue: authConfig},
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, SettingsService, Injector, Store],
      multi: true
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        biDiSupport: true,
        formatters: {upcase: (v: any) => v.toUpperCase()}
      }
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

function getState(store: Store<{ 'userProfile': UserProfile }>) {
  let state;

  store.pipe(take(1)).subscribe(s => state = s);

  return state;
}

