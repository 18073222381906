// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  uat: true,
  siteBaseUrl: 'https://uat-web.selfcare4me.com/',
  apiBffBaseUrl: 'https://uat-api.selfcare4me.com/bff-web/api/',
  apiAccountingBaseUrl: 'https://uat-api.selfcare4me.com/accounting/api/',
  apiChallengesBaseUrl: 'https://uat-api.selfcare4me.com/challenge-personal/api/',
  apiHealthBaseUrl: 'https://uat-api.selfcare4me.com/health/api/',
  apiIdentityBaseUrl: 'https://uat-sso.selfcare4me.com/api/',
  apiMeasurementBaseUrl: 'https://uat-api.selfcare4me.com/measurement/api/',
  apiMedMijUrl: 'https://staging-backend.selfcare4me.com/medmij',
  apiPaymentBaseUrl: 'https://uat-api.selfcare4me.com/payment/api/',
  apiProfileBaseUrl: 'https://uat-api.selfcare4me.com/profile/api/',
  apiResearchBaseUrl: 'https://uat-api.selfcare4me.com/research/api/',
  apiRewardBaseUrl: 'https://uat-api.selfcare4me.com/reward/api/',
  apiQuestionnaireBaseUrl: 'https://uat-api.selfcare4me.com/questionnaire/api/',
  signalRHubBaseUrl: 'https://uat-api.selfcare4me.com/badge-signalr/hubs/',
  signalRHubMeasurementBaseUrl: 'https://uat-api.selfcare4me.com/measurement-signalr/hubs/',
  ssoIssuer: 'https://uat-sso.selfcare4me.com',
  ssoRequiresHttps: true,
  cdnBaseUrl: 'https://cdn-selfcare-uat.azureedge.net/',
  routesToIgnoreInterceptor: [
    'https://uat-sso.selfcare4me.com/.well-known/openid-configuration',
    'https://uat-sso.selfcare4me.com/.well-known/openid-configuration/jwks',
    'https://uat-sso.selfcare4me.com/connect/token',
  ],
  routesToAcceptInHealthInterceptor: [
    'https://uat-api.selfcare4me.com/bff-web/api/dashboardCaregiver/',
    'https://uat-api.selfcare4me.com/health/api/'
  ],
  appInsightsKey: '94028ee1-1eb4-4a9c-a1b5-af93b7c0e7a5',
  adyenClientKey: 'test_F5USWJVXTVHKVBCKY2NVD2AP3IFVNWNS',
  appDeepLink: 'nl.selfcare.app-staging://deeplinks/',
  vital10BaseUrl: 'https://test-mijn.vital10.nl/', // TODO: move Vital10 URLs to backend so we can update them on the fly
  vital10ConnectUrl: 'https://test-mijn.vital10.nl/apparaten/connect',
  vital10DisconnectUrl: 'https://test-mijn.vital10.nl/apparaten/disconnect',
  featureFlags: [] as string[],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
