import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiHealthPlazaService } from 'src/app/shared/api/api-health-plaza.service';
import { LocalStorageKey } from 'src/app/shared/models/localStorageKeys';
import { ModalId } from 'src/app/shared/models/modalIds';


//Don't export it as this is only for internal (component) use.
interface LocalModalData {
  reward: number;
  alreadyHandled: boolean;
  errorOccurred: boolean;
  receivalType: 'qrCodeScanned' | 'followedLink';
}
@Component({
  selector: 'health-plaza-reward',
  templateUrl: './health-plaza-reward.component.html',
  styleUrl: './health-plaza-reward.component.scss'
})
export class HealthPlazaRewardComponent implements AfterViewInit{
  modalKey: string = ModalId.HEALTH_PLAZA_REWARD_MODAL;
  reward: number = 0;
  alreadyHandled: boolean = false;
  errorOccurred: boolean = false;
  receivalType: string = '';

  constructor(public modal: NgxSmartModalService, private readonly healthPlazaService: ApiHealthPlazaService) {
  }
  ngAfterViewInit(): void {
    this.modal.get(this.modalKey)?.onOpen.subscribe(() => {
      const modalData: LocalModalData = this.modal.getModalData(this.modalKey) as LocalModalData;
      this.reward = modalData.reward;
      this.alreadyHandled = modalData.alreadyHandled;
      this.errorOccurred = modalData.errorOccurred;
      this.receivalType = modalData.receivalType;
    });
  }

  getTitle() {
    if (this.receivalType === 'qrCodeScanned') {
      return 'HEALTH_PLAZA_REWARD_MODAL.QR_CODE_SCANNED_TITLE';
    } else if (this.receivalType === 'followedLink') {
      return 'HEALTH_PLAZA_REWARD_MODAL.FOLLOWED_LINK_TITLE';
    }
    return '';
  }

  getDescription() {
    if (this.receivalType === 'qrCodeScanned') {
      return 'HEALTH_PLAZA_REWARD_MODAL.QR_CODE_SCANNED_DESCRIPTION';
    } else if (this.receivalType === 'followedLink') {
      return 'HEALTH_PLAZA_REWARD_MODAL.FOLLOWED_LINK_DESCRIPTION';
    }
    return '';
  }

  async closeModal() {
    this.modal.resetModalData(this.modalKey);
    this.modal.close(this.modalKey);
  }
}
