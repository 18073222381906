import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {BUTTON_STYLES} from '../button/button.component';
import { createWidget, Widget } from '@typeform/embed';
import {ApiQuestionnaireService} from '../../api/api-questionnaire.service';
import {Store} from '@ngrx/store';
import UserProfile from '../../models/userProfile';
import { LocalStorageKey } from '../../models/localStorageKeys';


@Component({
  selector: 'typeform-popup',
  templateUrl: './typeform-popup.component.html',
  styleUrls: ['./typeform-popup.component.scss']
})
export class TypeformPopupComponent implements OnDestroy {
  @ViewChild('typeFormContainer') typeFormContainer!: ElementRef;
  modalId = 'typeFormModal';
  private widget: Widget;
  userId: string;

  typeFormContainerId = 'typeFormContainer';

  protected readonly BUTTON_STYLES = BUTTON_STYLES;

  constructor(public modal: NgxSmartModalService,
              private apiQuestionnaireService: ApiQuestionnaireService,
              private store: Store<{ 'userProfile': UserProfile }>) {
    this.store.subscribe(userProfile => {
      if (userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
  }

  async showTypeForm(){
    const questionnaireId = localStorage.getItem(LocalStorageKey.QUESTIONNAIRE_ID);
    const questionnaireSummary = await this.apiQuestionnaireService.getQuestionnaireSummary(questionnaireId);

    setTimeout(() => {
      this.widget = createWidget(questionnaireSummary.formTypeId, {
        container: document.getElementById(this.typeFormContainerId)!,
        height: '100%',
        width: '100%',
        hidden: {
          footer: 'false',
          thankyou: 'false'
        },
        opacity: 100,
        onSubmit: async () => {
          setTimeout(async () => {
            await this.apiQuestionnaireService.completeQuestionnaire(questionnaireId, this.userId);
            localStorage.removeItem(LocalStorageKey.QUESTIONNAIRE_ID);
            this.modal.close(this.modalId);
          }, 1000);
        },
        onReady: () => {
          console.log('Form ready');
        }
      });
    },0);
  }

  ngOnDestroy() {
    // Clean up the widget when component is destroyed
    if (this.widget) {
      // TypeForm doesn't provide a destroy method, but if needed,
      // you can remove the element from DOM here
    }
  }
}
