import { Injectable } from '@angular/core';
import { ApiAccountingService } from '../api/api-accounting.service';
import { IFeatureFlagProfile, FeatureFlagProfile } from '../models/featureFlagProfile';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureName } from '../models/dashboard/FeatureName';

@Injectable({
  providedIn: 'root'
})

export class FeatureProfileService {
    private ongoingRequest: Promise<FeatureFlagProfile> | null = null;
    private featureFlagProfile: FeatureFlagProfile | null = null;
    private featureFlagProfile$ = new BehaviorSubject<FeatureFlagProfile | null>(null);

    constructor(private readonly apiAccountingService: ApiAccountingService) {
      this.loadFeatureFlagProfile();
    }

    /*
    Ensure the service is loaded as singleton for the current user session
    It is mandatory for this service to expedite only 1 request if
    multiple async requests are fired at the same time.

    Use this method as follows

    this.featureProfileService.getFeatureFlagProfile().then(featureFlagProfile => {
      this.applyFeatureFlagsMenu(featureFlagProfile);
      this.isDataLoaded = true;
    });

    this ensures the static variables are set when executing the code while ensuring
    no simultaneous api calls and using in memory caching based on the
    private featureFlagProfile variable
    */
    public async getFeatureFlagProfile(forceReload: boolean = false): Promise<FeatureFlagProfile> {
      return !forceReload && this.featureFlagProfile ? this.featureFlagProfile : await this.loadFeatureFlagProfile();
    }

    /*
    reload the feature flag profile from remote.
    This will also trigger featureProfileChanges() observable afterwards
    */
    public async reloadFeatureFlagProfile(): Promise<FeatureFlagProfile> {
      this.featureFlagProfile = null;
      return await this.loadFeatureFlagProfile();
    }

    /*
    creates an observable to see when the feature flag profile changes

    Example:
      this.featureProfileService.featureProfileChanges()
        .pipe(
          filter(profile => profile !== null),
          takeUntil(this._unsubscribe$)
        )
        .subscribe(profile => {
          this.setFeatureFlagProfile(profile);
        });

    */
    public featureProfileChanges(): Observable<FeatureFlagProfile | null> {
      return this.featureFlagProfile$.asObservable();
    }

    /*
    First request ongoing request is set and the API call is performed
    When the second request is triggered this same call will be returned
    ensurring no multiple API calls are performed.

    After completion is the userFeatureProfile is returned
    */
    private async loadFeatureFlagProfile(): Promise<FeatureFlagProfile> {
      if (this.ongoingRequest) return await this.ongoingRequest;

      this.ongoingRequest = this.apiAccountingService.getFeatureProfile()
        .then(featureFlagProfile => {
          this.featureFlagProfile = new FeatureFlagProfile(featureFlagProfile.featureFlags);
          this.featureFlagProfile$.next(this.featureFlagProfile);
          this.ongoingRequest = null;
          return this.featureFlagProfile;
        });

      return await this.ongoingRequest;
  }

  // private MOCK_FEATURE_FLAG_PROFILE: IFeatureFlagProfile = {
  //   featureFlags: [
  //  // MENU section
  //  { id: 16, featureName: FeatureName.MENU_PHR, isEnabled: true, allowUserOverride: true, defaultActivated: true },
  //  { id: 18, featureName: FeatureName.MENU_PROFILE, isEnabled: true, allowUserOverride: true, defaultActivated: true },
  //  { id: 24, featureName: FeatureName.MENU_MEASUREMENTS, isEnabled: true, allowUserOverride: true, defaultActivated: true },
  //  { id: 25, featureName: FeatureName.MENU_MEDICAL, isEnabled: true, allowUserOverride: true, defaultActivated: true },
  //  { id: 26, featureName: FeatureName.MENU_KNOWLEDGE_BASE, isEnabled: true, allowUserOverride: true, defaultActivated: true },

  //  { id: 1,  featureName: FeatureName.MENU_DASHBOARD, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 15, featureName: FeatureName.MENU_GAMES, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 17, featureName: FeatureName.MENU_HISTORY, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 28, featureName: FeatureName.MENU_LOGOUT, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 14, featureName: FeatureName.MENU_NETWORK, isEnabled: true, allowUserOverride: false, defaultActivated: true },
  //  { id: 27, featureName: FeatureName.MENU_SETTINGS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },

  //  // PROFILE section
  //  { id: 19, featureName: FeatureName.PROFILE_GENERAL_INFORMATION, isEnabled: true, allowUserOverride: true, defaultActivated: false },
  //  { id: 20, featureName: FeatureName.PROFILE_HEALTHCARE_PROVIDER, isEnabled: true,  allowUserOverride: true, defaultActivated: true },
  //  { id: 21, featureName: FeatureName.PROFILE_MEDICAL_BACKGROUND, isEnabled: true,  allowUserOverride: true, defaultActivated: true },
  //  { id: 23, featureName: FeatureName.PROFILE_ALLERGIES, isEnabled: true, allowUserOverride: true, defaultActivated: true },
  //  { id: 22, featureName: FeatureName.PROFILE_VACCINATIONS, isEnabled: true, allowUserOverride: true, defaultActivated: true },

  //  // DASHBOARD section
  //  { id: 4,  featureName: FeatureName.DASHBOARD_ACTIVATE_NO_TILES, isEnabled: false, allowUserOverride: false, defaultActivated: true },
  //  { id: 3,  featureName: FeatureName.DASHBOARD_ACTIVATE_TILES, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 5,  featureName: FeatureName.DASHBOARD_EXERCISE_MINUTES, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 2,  featureName: FeatureName.DASHBOARD_LIFESTYLE_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 6,  featureName: FeatureName.DASHBOARD_MEDICAL_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 7,  featureName: FeatureName.DASHBOARD_BLOOD_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 8,  featureName: FeatureName.DASHBOARD_PHYSICAL_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 9,  featureName: FeatureName.DASHBOARD_MENTAL_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 10, featureName: FeatureName.DASHBOARD_CUSTOM_MEASUREMENTS, isEnabled: true,  allowUserOverride: false, defaultActivated: true },
  //  { id: 11, featureName: FeatureName.DASHBOARD_IMAGE_NOTES, isEnabled: false, allowUserOverride: false, defaultActivated: true },
  //  { id: 12, featureName: FeatureName.DASHBOARD_TEXT_NOTES, isEnabled: false, allowUserOverride: false, defaultActivated: true },
  //  { id: 13, featureName: FeatureName.DASHBOARD_AUDIO_NOTES, isEnabled: false, allowUserOverride: false, defaultActivated: true }
  //   ]
  // };

}
