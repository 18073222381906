/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any */
// noinspection JSUnusedGlobalSymbols

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Chart, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {TranslateService} from '@ngx-translate/core';
import {MeasurementService} from '../../../measurements/measurement.service';
import {DateService} from '../../../utils/date.service';
import {MEASUREMENT_TYPES, MeasurementLimitIndication} from '../../../models/enums/MeasurementEnums';
import {
  DataPoint,
  DataPointTooltipModel,
  GradientDataCanvas,
  GradientDataLineGraph,
  GraphType,
  MeasurementGraphDataModel,
  TimePeriod,
} from '../../../models/enums/GraphEnums';
import zoomPlugin from 'chartjs-plugin-zoom';

import 'chartjs-plugin-trendline';
import {UnitPipe} from '../../../pipes/unit.pipe';
import {Context} from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import {setChartDefaults} from '../chartjs';

/**
 * The label offset (in horizontal px) of the x-axis time tick labels related to the selected time period.
 * For the time periods where the label is longer, an offset is required to reach desired positioning of x-axis time tick labels.
 * Note: enable gridlines on the x-axis to understand to purpose of this
 */
const TIME_PERIOD_TO_X_AXIS_TICK_LABEL_OFFSET = {
  Day: 8,
  Week: 0,
  Month: 5,
  CalendarMonth: 5,
  // Quarter: 16,
  Trimester: 26,
  Year: 22,
  All: 24,
};
const TIME_PERIOD_TO_Y_AXIS_GRID_LINES_COUNT = {
  Day: 3,
  Week: 4,
  Month: 4,
  CalendarMonth: 4,
  // Quarter: 16,
  Trimester: 4,
  Year: 4,
  All: 4,
};
const TIME_PERIOD_TO_X_AXIS_STEP_COUNT = {
  Day: 6,
  Week: 1,
  Month: 7,
  CalendarMonth: 7,
  // Quarter: 16,
  Trimester: 4,
  Year: 3,
  All: 4,
};
// see: https://momentjs.com/docs/#/displaying/format/
const TIME_PERIOD_TO_Y_AXIS_UNIT_DISPLAY_FORMAT = {
  Day: {
    hour: 'kk',
    day: 'ddd',
  },
  Week: {
    hour: 'kk',
    day: 'ddd',
  },
  Month: {
    hour: 'kk',
    day: 'DD',
  },
  CalendarMonth: {
    hour: 'kk',
    day: 'DD',
  },
  // Quarter: 16,
  Trimester: {
    hour: 'kk',
    day: 'ddd',
    week: 'MMM',
  },
  Year: {
    hour: 'kk',
    day: 'ddd',
    month: 'MMM',
  },
  All: {
    hour: 'kk',
    day: 'ddd',
    quarter: 'yyyy',
    year: 'yyyy',
  },
};

const COLOR_DATA_POINT_DEFAULT = '#CECECE';

const BORDER_WIDTH_BAR_CHART_DAY = 6;
const BORDER_WIDTH_BAR_CHART_WEEK = 22;
const BORDER_WIDTH_BAR_CHART_YEAR = 18;

@Component({
  selector: 'measurement-chart',
  templateUrl: './measurement-chart.component.html',
  styleUrls: ['./measurement-chart.component.scss'],
  providers: [
    UnitPipe
  ]
})

export class MeasurementChartComponent implements OnInit, OnChanges {
  @ViewChild('chartCanvas') canvas: ElementRef;
  @ViewChild(BaseChartDirective) public ref: BaseChartDirective;

  @Input() chartData: MeasurementGraphDataModel | null = null;

  @Input() measurementTypeId: string | null = null;
  @Input() measurementSvg: string;
  @Input() measurementUnitString: string;
  @Input() measurementTypeTitle: string;
  @Input() chartTypeSelected: ChartType = GraphType.LINE_CHART;
  @Input() trendLineActivated = false;
  @Input() atalmedialMeasurement = false;
  @Input() forContactId: string;
  @Input() isCaregiver = false;

  @Output() panCompletedEvent = new EventEmitter();
  @Output() tooltipToggled = new EventEmitter();
  @Output() onAddNote = new EventEmitter<string>();

  constructor(
    public translate: TranslateService,
    public measurementService: MeasurementService,
    public dateService: DateService,
    private unitPipe: UnitPipe,
    private cdr: ChangeDetectorRef
  ) {
  }

  tooltipData: DataPointTooltipModel | null = {
    dataPoint: {
      x: new Date(),
      startDateIsoString: new Date().toISOString(),
      endDate: new Date(),
      endDateIsoString: new Date().toISOString(),
      y: 0,
      valueFormatted: '0',
      quality: MeasurementLimitIndication.MIDDLE,
      qualityColor: COLOR_DATA_POINT_DEFAULT,
      totalMeasurements: 0,
      totalMeasurementsFormatted: '0',
      addedManuallyCount: 0,
      addedManuallyCountFormatted: '0',
      addedBySensorCount: 0,
      addedBySensorCountFormatted: '0',
      addedByExternalProviderCount: 0,
      addedByExternalProviderCountFormatted: '0',
      measurements: [],
      element: {
        active: false,
      }
    },
    xPos: 0,
    yPos: 0,
    timePeriod: TimePeriod.DAY,
  };

  gradientData: GradientDataCanvas;

  addNote(noteId: string) {
    this.onAddNote.emit(noteId);
  }

  getPointColor = (context: Context): string => {
    const dataPoint: DataPoint = context.dataset.data[context.dataIndex] as unknown as DataPoint;
    if (!dataPoint || this.trendLineActivated) {
      return COLOR_DATA_POINT_DEFAULT;
    }
    return dataPoint.qualityColor;
  };

  getBorderColor = (context: Context): string => {
    if (this.chartTypeSelected === GraphType.LINE_CHART) {
      return null;
    }
    const dataPoint: DataPoint = context.dataset.data[context.dataIndex] as unknown as DataPoint;
    if (!dataPoint) {
      return COLOR_DATA_POINT_DEFAULT;
    }
    return dataPoint.qualityColor;
  };

  getLineGradient = (context: Context): CanvasGradient => {
    if (this.chartTypeSelected === GraphType.BAR_CHART) {
      return null;
    }

    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      return null;
    }

    if (!this.gradientData) {
      this.gradientData = {
        gradient: null,
        width: 0,
        height: 0,
      };
    }

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    if (
      !this.gradientData.gradient ||
      this.gradientData.width !== chartWidth ||
      this.gradientData.height !== chartHeight) {

      this.gradientData.width = chartWidth;
      this.gradientData.height = chartHeight;

      this.gradientData.gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

      const yAxisRendered = chart.scales['y'];

      if (yAxisRendered && this.chartData && this.chartData.gradientDataLineGraph) {
        const gradientData: GradientDataLineGraph[] = this.chartData.gradientDataLineGraph;
        const minYTick = yAxisRendered.min;
        const maxYTick = yAxisRendered.max;
        const YTickRange = maxYTick - minYTick;

        const gradientDataParsedByYAxis = [];

        let highestLimitValueInRange = gradientData[0];
        let lowestLimitValueInRange = gradientData[gradientData.length - 1];
        let inRangeLimitValues = 0;

        for (const entry of gradientData) {
          if (entry.value >= minYTick && entry.value <= maxYTick) {
            inRangeLimitValues++;
          }
        }

        if (inRangeLimitValues <= 1) {
          if (minYTick >= highestLimitValueInRange.value) {
            this.gradientData.gradient.addColorStop(0, highestLimitValueInRange.color);
            this.gradientData.gradient.addColorStop(1, highestLimitValueInRange.color);
          } else if (maxYTick <= lowestLimitValueInRange.value) {
            this.gradientData.gradient.addColorStop(0, lowestLimitValueInRange.color);
            this.gradientData.gradient.addColorStop(1, lowestLimitValueInRange.color);
          } else {
            console.warn('Falling back to grey data lines: debug required');
            this.gradientData.gradient.addColorStop(0, COLOR_DATA_POINT_DEFAULT);
            this.gradientData.gradient.addColorStop(1, COLOR_DATA_POINT_DEFAULT);
          }
          return this.gradientData.gradient;
        }

        for (const entry of gradientData) {
          if (entry.value > minYTick) {
            highestLimitValueInRange = entry;
            break;
          }
        }
        for (let i = gradientData.length - 1; i >= 0; i--) {
          const entry = gradientData[i];
          if (entry.value < maxYTick) {
            lowestLimitValueInRange = entry;
            break;
          }
        }

        gradientDataParsedByYAxis.push({
          startingPointToDrawGradientYAxis: 0,
          color: highestLimitValueInRange.color,
        });

        if (inRangeLimitValues > 2) {
          for (const entry of gradientData) {
            if (entry.value >= minYTick && entry.value <= maxYTick) {
              const gradientValueWithYAxisRange = maxYTick - entry.value;
              const gradientStartPoint = gradientValueWithYAxisRange / YTickRange;
              gradientDataParsedByYAxis.push({
                startingPointToDrawGradientYAxis: gradientStartPoint,
                color: entry.color,
              });
            }
          }
        }

        gradientDataParsedByYAxis.push({
          startingPointToDrawGradientYAxis: 1,
          color: lowestLimitValueInRange.color,
        });

        let returnAsGradient = true;

        gradientDataParsedByYAxis.forEach((gradientEntry: any) => {
          if (gradientEntry.startingPointToDrawGradientYAxis < 0 || gradientEntry.startingPointToDrawGradientYAxis > 1) {
            console.error('Gradient stroke data contains invalid values', gradientEntry);
            returnAsGradient = false;
          } else {
            this.gradientData.gradient.addColorStop(gradientEntry.startingPointToDrawGradientYAxis, gradientEntry.color);
          }
        });

        if (!returnAsGradient) {
          this.gradientData.gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          this.gradientData.gradient.addColorStop(0, COLOR_DATA_POINT_DEFAULT);
          this.gradientData.gradient.addColorStop(1, COLOR_DATA_POINT_DEFAULT);
        }
      } else {
        // Fallback if no gradientDataLineGraph is available
        this.gradientData.gradient.addColorStop(0, 'red');
        this.gradientData.gradient.addColorStop(0.5, 'yellow');
        this.gradientData.gradient.addColorStop(1, 'green');
      }
    }

    return this.gradientData.gradient;
  };

  isDataPoint(obj: any): obj is DataPoint {
    return obj && typeof obj === 'object' && 'qualityColor' in obj;
  }

  public datasets: any = [
    {
      data: [],
      fill: false,
      pointStyle: 'circle',
      backgroundColor: this.getPointColor,
      pointBackgroundColor: this.getPointColor,
      pointBorderColor: this.getPointColor,
      hoverBackgroundColor: (context: Context) => {
        const rawData = context.dataset.data[context.dataIndex];

        // If rawData is a number, return default color
        if (typeof rawData === 'number') {
          return COLOR_DATA_POINT_DEFAULT;
        }
      
        // Type guard to check if rawData is a DataPoint
        if (this.isDataPoint(rawData)) {
          return rawData.qualityColor || COLOR_DATA_POINT_DEFAULT;
        }

        return COLOR_DATA_POINT_DEFAULT;
      },
      hoverBorderColor: (context: Context) => {
        const rawData = context.dataset.data[context.dataIndex];

        if (typeof rawData === 'number') {
          return COLOR_DATA_POINT_DEFAULT;
        }

        if (this.isDataPoint(rawData)) {
          return rawData.qualityColor || COLOR_DATA_POINT_DEFAULT;
        }

        return COLOR_DATA_POINT_DEFAULT;
      },
      pointBorderWidth: 6,
      pointHoverRadius: 8,
      pointHitRadius: 6,
      tension: 0.4, // line curvature
      barThickness: (): number | string => {
        if (this.chartData) {
          if (this.chartData.timePeriod === TimePeriod.WEEK) {
            return BORDER_WIDTH_BAR_CHART_WEEK;
          }
          if (
            this.chartData.timePeriod === TimePeriod.TRIMESTER ||
            this.chartData.timePeriod === TimePeriod.YEAR ||
            this.chartData.timePeriod === TimePeriod.ALL
          ) {
            return BORDER_WIDTH_BAR_CHART_YEAR;
          }
        }

        return BORDER_WIDTH_BAR_CHART_DAY;
      },
      borderColor: this.getLineGradient,
      borderWidth: () => this.chartTypeSelected === GraphType.LINE_CHART ? 3 : 0,
    },
  ];

  // see: ChartOptions
  public options: any = {
    animation: false,
    animations: {
      x: {
        duration: 0,
      },
      y: {
        from: ({ chart }: Context) => chart.chartArea.bottom,
      },
    },
    scales: {
      x: {
        adapters: {
          date: {
            locale: this.translate.getBrowserLang(),
          },
        },
        display: true,
        type: 'time',
        offset: this.chartTypeSelected === GraphType.BAR_CHART,
        distribution: 'linear',
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          labelOffset: TIME_PERIOD_TO_X_AXIS_TICK_LABEL_OFFSET.Day,
        },
        title: {
          display: true,
          text: this.translate.instant('MEASUREMENT_DETAILS.CHARTS.TIMESTAMP_LABEL').toUpperCase()
        },
        time: {
          unit: this.chartData?.xAxisTicksUnit ?? 'hour',
          stepSize: TIME_PERIOD_TO_X_AXIS_STEP_COUNT.Day,
          displayFormats: TIME_PERIOD_TO_Y_AXIS_UNIT_DISPLAY_FORMAT.Day,
          isoWeekday: true,
        },
      },
      y: {
        display: true,
        type: 'linear',
        position: 'left',
        ticks: {
          maxTicksLimit: TIME_PERIOD_TO_Y_AXIS_GRID_LINES_COUNT[TimePeriod.DAY],
          callback: (value: any, _: any) => this.unitPipe.transform(value, this.measurementUnitString, true, false, null, true, true, MEASUREMENT_TYPES[this.measurementTypeId]?.roundValueToDecimals)
        },
        title: {
          display: true,
          text: ''
        },
        grid: {
          borderDash: [3, 2],
          color: '#8D8489',
          drawBorder: false,
        },
      },
      elements: {
        display: false
      }
    },
    responsive: true,
    events: ['click'],
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 0,
        bottom: 0
      },
    },
    title: {
      display: false
    },
    legend: {
      display: false
    },
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'point',
        intersect: true,
        external: (tooltipData: any) => {
          const tooltipModel = tooltipData?.tooltip;

          let tooltipValue = {
            dataPoint: this.tooltipData.dataPoint,
            xPos: tooltipModel.caretX || 0,
            yPos: tooltipModel.caretY || 0,
            timePeriod: this.chartData?.timePeriod || null,
          };

          if (tooltipModel.opacity === 0) {
            this.tooltipData = tooltipValue;
            this.tooltipToggled.emit(false);
            this.cdr.detectChanges();
            return;
          }

          if (tooltipModel.body && tooltipModel.dataPoints.length > 0) {
            const tooltipItem = tooltipModel.dataPoints[0];
            let dataPoint = tooltipItem?.raw;
            dataPoint.element = tooltipItem?.element;

            tooltipValue.dataPoint = dataPoint;
            this.tooltipData = tooltipValue;
            this.tooltipToggled.emit(true);
            this.cdr.detectChanges();
          }
        },
        callbacks: {
          afterBody: (context: string | any[]) => {
            if (!context || context.length === 0) return;

            const data = context[0];
            if (!data || !data.dataset || !data.dataset.data) return;

            const index = data.dataIndex;
            const datasetData = data.dataset.data;

            return datasetData[index];
          }
        }
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          threshold: 50,
          speed: 1,
          onPanComplete: () => this.onPanCompleted(),
        },
      },
    }
  };

  ngOnInit(): void {
    setChartDefaults();
    Chart.register(...[
      zoomPlugin,
    ]);
  }

  tooltipClose(): void {
    this.tooltipToggled.emit(false);
    this.ref.chart.setActiveElements([]);
    this.ref.chart.tooltip.setActiveElements([], { x: 0, y: 0 });
    this.ref.chart.update('none');
  }

  onPanCompleted(): void {
    this.tooltipToggled.emit(false);
    const xScale = this.ref.chart.scales['x'];
    const minDate = new Date(xScale.min);
    const maxDate = new Date(xScale.max);

    const panFinishedObject = {minDate, maxDate};

    this.panCompletedEvent.emit(panFinishedObject);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.chartData && changes.chartData.currentValue) ||
      (changes.chartTypeSelected && changes.chartTypeSelected.currentValue)
    ) {
      this.setGraphData();
      this.renderGraph();
    }
  }

  renderGraph(): void {
    this.options = {...this.options};

    if (this.ref) {
      this.ref.chart.data.datasets = this.datasets;
      this.options.scales.y.suggestedMin = Math.min(...this.datasets[0].data.map((d: any) => d.y));
      this.options.scales.y.suggestedMax = Math.max(...this.datasets[0].data.map((d: any) => d.y));
      this.ref.chart.options = this.options;
      this.ref.chart.update();
    }
  }

  /**
   * Function that sets the data necessary for a fully rendered graph based on chartData: MeasurementGraphDataModel
   */
  setGraphData(): void {
    if (!this.chartData) return;

    this.options.scales.x.min = this.chartData.xAxisTicksStartDate;
    this.options.scales.x.max = this.chartData.xAxisTicksEndDate;
    this.options.scales.x.time.unit = this.chartData.xAxisTicksUnit;
    this.options.scales.x.ticks.labelOffset = TIME_PERIOD_TO_X_AXIS_TICK_LABEL_OFFSET[this.chartData.timePeriod];
    this.options.scales.x.title.text = this.chartData.xAxisLabel;
    this.options.scales.x.offset = this.chartTypeSelected === GraphType.BAR_CHART;
    this.options.scales.x.time.stepSize = TIME_PERIOD_TO_X_AXIS_STEP_COUNT[this.chartData.timePeriod];
    this.options.scales.x.time.displayFormats = TIME_PERIOD_TO_Y_AXIS_UNIT_DISPLAY_FORMAT[this.chartData.timePeriod];

    this.options.scales.y.title.text = this.chartData.yAxisLabel;
    this.options.scales.y.ticks.maxTicksLimit = TIME_PERIOD_TO_Y_AXIS_GRID_LINES_COUNT[this.chartData.timePeriod];
    this.options.scales.y.suggestedMin = this.chartTypeSelected === GraphType.BAR_CHART && this.chartData.yAxisMinValue !== 0
      ? this.chartData.yAxisMinValue - 0.001
      : undefined;

    this.options.animation = this.chartData.animateDataPoints;

    this.datasets[0].data = this.chartData.dataPoints;
  }

  // /**
  //  * Toggles the inclusion of a 'trendline' for bar and line charts
  //  * @param {boolean} activated
  //  * @param {boolean} renderGraph
  //  */
  // toggleTrendline(activated: boolean, renderGraph = false): void {
  //   if (activated) {
  //     this.datasets[0].trendlineLinear = {
  //       style: '#EE801C',
  //       lineStyle: 'solid',
  //       width: 4
  //     };
  //   } else {
  //     this.datasets[0].trendlineLinear = null;
  //   }
  //   if (renderGraph) this.renderGraph();
  // }

  // events, unused but leave commented for now
  // public chartClicked({ event, active }: { event?: MouseEvent; active?: [] | {} }): void {
  //   console.log('random click on graph', event, active);
  // }
  //
  // /**
  //  * Clamp the x-axis (time) to a specific interval:
  //  * For interval Day: 00:00 -> 23:59
  //  * For interval Week: Monday -> Sunday
  //  * For interval Month: first day of month -> last day of month
  //  * etc.
  //  * @param {TimePeriod} timeSpan
  //  * @param {Date} tickStart
  //  * @param {Date} tickEnd
  //  */
  // clampXAxisToInterval(timeSpan: TimePeriod, tickStart: Date, tickEnd: Date): void {
  //   const today = tickStart.getDate();
  //
  //   tickStart.setSeconds(0);
  //   tickStart.setMinutes(45);
  //   tickStart.setHours(23);
  //   tickEnd.setSeconds(0);
  //   tickEnd.setHours(0);
  //   tickEnd.setMinutes(15);
  //
  //   if (timeSpan === TimePeriod.DAY) {
  //     tickStart.setDate(today - 1);
  //     tickEnd.setDate(today + 1);
  //   }
  //   else if (timeSpan === TimePeriod.WEEK) {
  //     tickStart.setSeconds(0);
  //     tickStart.setMinutes(0);
  //     tickStart.setHours(12);
  //     tickEnd.setSeconds(0);
  //     tickEnd.setMinutes(0);
  //     tickEnd.setHours(12);
  //     tickStart.setDate((tickStart.getDate() - (tickStart.getDay() + 6) % 7) - 1);
  //     tickEnd.setDate(tickStart.getDate() + 7);
  //   }
  //   else if (timeSpan === TimePeriod.MONTH) {
  //     const totalDaysInMonth = new Date(tickStart.getFullYear(), tickStart.getMonth() + 1, 0).getDate();
  //     // console.log('totalDaysInMonth', totalDaysInMonth);
  //     tickStart.setDate(1);
  //     tickStart.setHours(0);
  //     tickStart.setMinutes(0);
  //     tickEnd.setMonth(tickStart.getMonth());
  //     tickEnd.setDate(totalDaysInMonth);
  //     tickEnd.setHours(0);
  //     tickEnd.setMinutes(0);
  //   }
  //   else if (timeSpan === TimePeriod.QUARTER) {
  //     const currentMonth = tickStart.getMonth();
  //     tickStart.setDate(0);
  //     tickEnd.setDate(0);
  //     if (currentMonth <= 2) {
  //       tickStart.setMonth(0);
  //       tickEnd.setMonth(3);
  //     }
  //     else if (currentMonth <= 5) {
  //       tickStart.setMonth(2);
  //       tickEnd.setMonth(5);
  //     }
  //     else if (currentMonth <= 8) {
  //       tickStart.setMonth(5);
  //       tickEnd.setMonth(8);
  //     }
  //     else {
  //       tickStart.setMonth(8);
  //       tickEnd.setMonth(11);
  //     }
  //   }
  //   else if (timeSpan === TimePeriod.YEAR) {
  //     const currentYear = tickStart.getFullYear();
  //     // begin.setDate(1);
  //     tickStart.setFullYear(currentYear - 1, 11, 31);
  //     tickEnd.setFullYear(currentYear, 11, 31);
  //   }
  //   else if (timeSpan === TimePeriod.ALL) {
  //     tickStart = this.chartData.minX;
  //     tickStart.setDate(tickStart.getDate() - 1);
  //     tickEnd = this.chartData.maxX;
  //     tickEnd.setDate(tickEnd.getDate() + 1);
  //   }
  // }
}
