import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiPaymentService } from '../../api/api-payment.service';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import UserProfile from '../../models/userProfile';
import { take } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import PaymentInfo from '../../../shared/models/paymentInfo';
import {Subscription} from 'rxjs';
import { AdyenCheckout, CoreConfiguration, Dropin, Card, Bancontact, SepaDirectDebit } from '@adyen/adyen-web';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'payment-dropin',
  templateUrl: './payment-dropin.component.html',
  styleUrls: ['./payment-dropin.component.scss']
})
export class PaymentDropinComponent implements OnInit {
  @Output() paymentFinalized = new EventEmitter<string>();
  sub: Subscription;

  countryCode = 'NL';
  cultureCode = 'nl-NL';
  paymentInfo: PaymentInfo;
  subscriptionAmount = 2.99;
  currencyCode = 'EUR';

  //Update this map as we support more payment methods
  paymentMethodComponentMap = {
    scheme: Card,
    bcmc: Bancontact,
    sepadirectdebit: SepaDirectDebit
  };


  constructor(
    private readonly translsate: TranslateService,
    private readonly apiPaymentService: ApiPaymentService,
    private readonly store: Store<{ 'userProfile': UserProfile }>,
    private readonly apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.sub = this.store.pipe(take(1)).subscribe(store => {
      this.countryCode = store.userProfile.generalInformation.nationality;
      this.setPaymentInfo().then(() => {
        this.apiPaymentService.getPaymentMethods(this.countryCode).subscribe(res => {
          document.getElementById('dropin-container').scrollIntoView();

          const configuration: CoreConfiguration = {
            paymentMethodsResponse: {
              paymentMethods: res.paymentMethods
            }, // The `/paymentMethods` response from the server.
            countryCode: this.countryCode,
            clientKey: environment.adyenClientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
            locale: this.cultureCode,
            environment: environment.production ? 'live' : 'test',
            // returnUrl: `${environment.siteBaseUrl}dashboard/settings/financial`,
            amount: {
              currency: this.currencyCode,
              value: (this.subscriptionAmount * 100)
            },
            // @ts-ignore
            onSubmit: (state: any, component: any) => {
              if (state.isValid) {
                this.initiatePayment(state, component);
              }
            },
            onAdditionalDetails: (state: any, component: any) => {
              this.submitAdditionalDetails(state, component);
            },
          };

          const checkout = AdyenCheckout(configuration);
          //Any unkown payment methods will be removed from the response as they wil not be abled to be maped
          //If this happends add them to the paymentMethodComponentMap
          const availableComponents = res.paymentMethods
            .map(pm => pm.type)
            .filter((type): type is keyof typeof this.paymentMethodComponentMap => type in this.paymentMethodComponentMap)
            .map(type => this.paymentMethodComponentMap[type]);

          checkout.then(checkout => {
            const dropIn = new Dropin(checkout, {
              paymentMethodComponents: availableComponents,
              openFirstPaymentMethod: false,
            });

            dropIn.mount('#dropin-container');
          });
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async initiatePayment(state: any, component: any) {
    try {
      state.data.returnUrl = `${environment.siteBaseUrl}dashboard/settings/financial`;
      state.data.countryCode = this.countryCode;
      this.apiPaymentService.initiatePayment(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async submitAdditionalDetails(state: any, component: any) {
    try {
      this.apiPaymentService.submitAdditionalDetails(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleServerResponse(res: any, component: any) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      this.paymentFinalized.emit(res.resultCode);
    }
  }

  async setPaymentInfo() {
    this.paymentInfo = await this.apiService.getPaymentInfo();
    const userLanguage = this.translsate.currentLang.toLowerCase();
    //Adyen expects values like nl-NL, fr-FR, etc. This means we can't use the culture code like we store them because this would be nl-be
    //Therefore we just use active language an construct the value that adyen understands.
    this.cultureCode = `${userLanguage}-${userLanguage.toUpperCase()}`;

    if(this.paymentInfo != null) {
      this.subscriptionAmount = this.paymentInfo.monthlySubscriptionAmount;
      this.currencyCode = this.paymentInfo.currencyCode;
    }
  }
}
