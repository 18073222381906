import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../menu.service';
import { FeatureProfileService } from '../../../../shared/utils/feature-profile-service';
import { FeatureFlagProfile } from '../../../../shared/models/featureFlagProfile';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nav-bottom-menu',
  templateUrl: './nav-bottom-menu.component.html',
  styleUrls: [ './nav-bottom-menu.component.scss' ],
})
export class NavBottomMenuComponent implements OnInit {
  private _unsubscribe$ = new Subject();

  constructor(
    readonly router: Router,
    private featureProfileService :FeatureProfileService
  ) {
  }

  public MENU_ITEMS: MenuItem[] = [];
  // Icons that have iconActive are not styled using CSS and have a completely different SVG depending on active or not
  // Icons without iconActive are styled using CSS depending on whether they're active (orange color) or not (grey color).
  private MENU_ITEMS_DEF: MenuItem[] = [
    {
      id: 'DASHBOARD',
      icon: 'icn_dashboard_not_active.svg',
      iconActive: 'icn_dashboard_active.svg',
      routerLink: '/dashboard/home',
    },
    {
      id: 'NETWORK',
      icon: 'icn_network.svg',
      iconActive: 'icn_network_active.svg',
      routerLink: '/dashboard/network',
    },
    {
      id: 'GAMES',
      icon: 'icn_games.svg',
      routerLink: '/dashboard/games',
    },
    {
      id: 'MEDICAL',
      icon: 'icn_medical.svg',
      routerLink: '/dashboard/medical/medication',
    },
    {
      id: 'PHR',
      icon: 'icn_phr.svg',
      iconActive: 'icn_phr_active.svg',
      routerLink: '/dashboard/phr',
    },
  ];

  async ngOnInit(): Promise<void> {
    this.featureProfileService.featureProfileChanges()
      .pipe(
        filter(profile => profile !== null),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(profile => {
        this.setFeatureFlagProfile(profile);
      });

    await this.featureProfileService.getFeatureFlagProfile()
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  private setFeatureFlagProfile(featureFlagProfile: FeatureFlagProfile) {
    if(!featureFlagProfile) return;

    this.MENU_ITEMS = this.MENU_ITEMS_DEF.filter(menuItem =>
      !featureFlagProfile.hasFeatureFlagDisabled('MENU', menuItem.id)
    );
  }
}
