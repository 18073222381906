import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ResearchIdWithQuestionnaireId} from "../models/settings/researchSettings";

@Injectable({
  providedIn: 'root'
})
export class ApiQuestionnaireService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiQuestionnaireBaseUrl;
  }

  completeQuestionnaire(workflowId: string, userId: string): Promise<void> {
    return this.http.post<void>(`${this.baseUrl}questionnaire/workflow/${workflowId}/complete/${userId}`, null).toPromise();
  }

  getQuestionnaireSummary(workflowId: string): Promise<QuestionnaireSummary> {
    return this.http.get<QuestionnaireSummary>(`${this.baseUrl}questionnaire/workflow/${workflowId}/summary`).toPromise();
  }

  consentForQuestionnaire(workflowId: string, userId: string): Promise<ResearchIdWithQuestionnaireId[]> {
    return this.http.post<ResearchIdWithQuestionnaireId[]>(`${this.baseUrl}questionnaire/workflow/${workflowId}/recipient/${userId}/consent`, null).toPromise();
  }
}

export interface QuestionnaireSummary {
  formTypeId: string
  productId: number,
  productType: string,
  customerId: number
}
