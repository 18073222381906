import {
  AfterViewInit,
  Component,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewInit {
  // Saving in localstorage to know if it is opened or not
  @Input() id: string;
  @Input() arrowColor = '#70B3E2';
  @Input() isLast = false;
  @Input() addShadow = false;
  @Input() openByDefault = false;
  @Input() ignoreLocalStorage = false;
  @Input() useSessionStorage = false;
  @Input() enableChangeDetection = true; // you want to set this to false when you have issues with the visibility of your accordion

  @Output() onToggle = new EventEmitter<boolean>();
  @Input() isMainAccordion = false;

  isOpened = false;
  transitionSpeedSeconds = 0.25;

  @ViewChild('accordionElement') accordionElement: ElementRef;
  @ViewChild('accordionContent') accordionContent: ElementRef;
  @ViewChild('expandArrow') expandArrow: ElementRef;
  @ViewChild('accordionContentClosed') closedContent: ElementRef;

  // constructor(
  //
  // ) { }

  ngOnInit(): void {
    if (this.id && !this.ignoreLocalStorage) {
      this.checkIfOpenedInLocalStorage();
    } else if(this.id && this.useSessionStorage) {
      this.checkIfOpenedInSessionStorage();
    } else {
      this.isOpened = this.openByDefault;
    }
  }

  ngAfterViewInit(): void {
    const contentElement = <HTMLElement>this.accordionContent.nativeElement;
    const arrowElement = <HTMLElement>this.expandArrow.nativeElement;
    const closedContent = <HTMLElement>this.closedContent.nativeElement;
    if (this.isOpened && !<HTMLElement>this.accordionContent.nativeElement.style.height) {
      // By setting the max-height to a height it never gets, the auto height is set. When setting the height to auto, the transition doesn't work.
      contentElement.style.maxHeight = '30000rem';
      arrowElement.style.transform = 'rotate(225deg)';
    }

    // Set transitions
    contentElement.style.transition = `max-height ${this.transitionSpeedSeconds}s ease`;
    arrowElement.style.transition = `transform ${this.transitionSpeedSeconds}s ease`;
    closedContent.style.transition = `height ${this.transitionSpeedSeconds}s ease, opacity ${this.transitionSpeedSeconds}s ease`;
  }

  checkIfOpenedInLocalStorage(): void {
    let localStorageAccordionState = localStorage.getItem(this.id);

    if (localStorageAccordionState) {
      localStorageAccordionState = JSON.parse(localStorageAccordionState);
      this.isOpened = !!localStorageAccordionState;
    } else {
      this.isOpened = this.openByDefault;
      localStorage.setItem(this.id, this.isOpened ? 'true' : 'false');
    }
  }

  checkIfOpenedInSessionStorage(): void {
    let sessionStorageAccordionState = sessionStorage.getItem(this.id);

    if (sessionStorageAccordionState) {
      sessionStorageAccordionState = JSON.parse(sessionStorageAccordionState);
      this.isOpened = !!sessionStorageAccordionState;
    } else {
      this.isOpened = this.openByDefault;
      sessionStorage.setItem(this.id, this.isOpened ? 'true' : 'false');
    }
  }

  toggleAccordion(emitToParent = true): void {
    const contentElement = <HTMLElement>this.accordionContent.nativeElement;
    const arrowElement = <HTMLElement>this.expandArrow.nativeElement;
    const closedContent = <HTMLElement>this.closedContent.nativeElement;
    // || contentElement.style.height == '6.5rem'
    if (contentElement.style.maxHeight && contentElement.style.maxHeight !== '0px') {
      contentElement.style.maxHeight = contentElement.scrollHeight + 'px';
      // closing
      contentElement.style.maxHeight = null;
      arrowElement.style.transform = 'rotate(45deg)';
      closedContent.style.maxHeight = closedContent.scrollHeight + 'px';
      closedContent.style.opacity = '1';
    } else {
      // opening
      contentElement.style.maxHeight = contentElement.scrollHeight + 'px';
      arrowElement.style.transform = 'rotate(225deg)';
      closedContent.style.maxHeight = null;
      closedContent.style.opacity = '0';
    }
    this.isOpened = !this.isOpened;
    if (this.id) localStorage.setItem(this.id, this.isOpened ? 'true': 'false');
    if (this.id && this.useSessionStorage) sessionStorage.setItem(this.id, this.isOpened ? 'true': 'false');
    if (emitToParent) this.onToggle.emit(this.isOpened);
  }

  closeAccordion(): void {
    if (!this.isOpened) return;
    this.toggleAccordion(true);
  }

  recalcAccordionHeight() {
    setTimeout(() => {
      //this can be used when some dynamic content is displayed in the accordion. Should be triggered after height changes
      const contentElement = <HTMLElement>this.accordionContent.nativeElement;
      contentElement.style.maxHeight = contentElement.scrollHeight + 'px';
    }, 300);
  }

  onContentChange(changes: MutationRecord[]) {
    if(this.enableChangeDetection) {
      // logs everything that changed
      changes.forEach(change => {
        if(this.isOpened) {
          this.recalcAccordionHeight();
        }
      });
    }
  }
}
