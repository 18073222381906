import { FeatureName } from './dashboard/FeatureName';

export interface IFeatureFlag {
    readonly id: number;
    readonly featureName: FeatureName;
    readonly description?: string;
    readonly isEnabled: boolean;
    readonly defaultActivated: boolean;
    readonly allowUserOverride: boolean;
}

export class FeatureUserFlagUpdate {
    readonly isEnabled: boolean;
    readonly featureFlagDefinitionId: number;

    constructor(
        isEnabled: boolean,
        featureFlagDefinitionId: number
    ){
        this.isEnabled = isEnabled;
        this.featureFlagDefinitionId = featureFlagDefinitionId;
    }
}

export class FeatureFlag implements IFeatureFlag{
    readonly id: number;
    readonly sectionName: string;
    readonly featureName: FeatureName;
    readonly featureShortName: string;
    readonly description: string;
    readonly isEnabled: boolean;
    readonly defaultActivated: boolean;
    readonly allowUserOverride: boolean;
    readonly translationKey: string;

    constructor(
        id: number,
        featureName: FeatureName,
        description: string,
        isEnabled: boolean,
        defaultActivated: boolean,
        allowUserOverride: boolean,
    ) {
        const featueNameParts = featureName.split('_');

        this.id = id;
        this.featureName = featureName;
        this.sectionName = featueNameParts.shift();
        this.featureShortName = featueNameParts.join('_'); // used after sectionName shift
        this.description = description ?? '';
        this.isEnabled = isEnabled;
        this.defaultActivated = defaultActivated ?? true;
        this.allowUserOverride = allowUserOverride;
        this.translationKey = this.getTranslationKey(); // set after section
    }

    private getTranslationKey(): string {
        switch (this.sectionName) {
            case 'MENU':
                return `DASHBOARD.HAMBURGER_MENU.${this.featureShortName}`;
            case 'DASHBOARD':
                return `DASHBOARD.ELEMENTS.${this.featureShortName}`;
            case 'PROFILE':
                return `PROFILE_DETAILS.TITLES.${this.featureShortName}`;
        }

        return `SHARED.FEATURE_FLAGS.${this.featureShortName}`;
    }
}
