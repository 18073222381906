import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import UserProfile from '../models/userProfile';
import { GetPaymentMethodsResponse } from '../models/payments/payment';

@Injectable({
  providedIn: 'root'
})
export class ApiPaymentService {
  private readonly baseUrl: string;
  private userId: string;
  private readonly sub: Subscription;

  constructor(private http: HttpClient, private store: Store<{ 'userProfile': UserProfile }>) {
    this.baseUrl = environment.apiPaymentBaseUrl;
    this.sub = this.store.subscribe(userProfile => {
      if(userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  initiatePayment(data: any): Observable<any> {
    return this.http.post<string>(`${this.baseUrl}AdyenPayment/initiatePayment`, data);
  }

  getPaymentMethods(countryCode: string): Observable<GetPaymentMethodsResponse> {
    return this.http.get<GetPaymentMethodsResponse>(`${this.baseUrl}AdyenPayment/${this.getUserId()}/paymentMethods/${countryCode}`);
  }

  submitAdditionalDetails(data: any): Observable<any> {
    return this.http.post<string>(`${this.baseUrl}AdyenPayment/submitAdditionalDetails`, data);
  }

  submitAdditionalDetailsRedirect(redirectResult: string): Observable<any> {
    return this.http.post<string>(`${this.baseUrl}AdyenPayment/submitAdditionalDetails/action/redirect`, {redirectResult: redirectResult});
  }

  // Get user id post login
  public getUserId(): string | null {
    return this.userId;
  }
}
