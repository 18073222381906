import { Component } from '@angular/core';

@Component({
  selector: 'health-plaza-block-coming-soon',
  templateUrl: './health-plaza-block-coming-soon.component.html',
  styleUrls: ['./health-plaza-block-coming-soon.component.scss']
})
export class HealthPlazaBlockComingSoonComponent {

}
