import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IFeatureFlagProfile } from '../models/featureFlagProfile';
import { FeatureUserFlagUpdate } from '../models/featureFlag';
import { catchError, map } from 'rxjs/operators';
import { FeatureName } from '../models/dashboard/FeatureName';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiAccountingService {

  private readonly baseUrl = environment.apiAccountingBaseUrl;

  constructor(
    private httpAccountingClient: HttpClient
  ) {
  }

  async getFeatureProfile(): Promise<IFeatureFlagProfile | null> {
    return this.httpAccountingClient
    .get<IFeatureFlagProfile>(`${this.baseUrl}feature-flags`)
    .pipe(
      map((profile) => ({
        ...profile,
        featureFlags: profile.featureFlags.map(flag => ({
          ...flag,
          featureName: FeatureName[flag.featureName as keyof typeof FeatureName] || flag.featureName
        }))
      })),
      catchError((error) => {
        console.error('Error fetching feature profile:', error);
        return of(null); // Return `null` on error
      })
    )
    .toPromise();
  }

  async updateUserFeatureFlagProfile(userFlagUpdateModel: FeatureUserFlagUpdate[]): Promise<void> {
    return this.httpAccountingClient.put<void>(`${this.baseUrl}feature-flags/savefeatureflaguser`, userFlagUpdateModel).toPromise();
  }
}
