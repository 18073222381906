import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Store} from "@ngrx/store";
import UserProfile from "../models/userProfile";

@Injectable({
  providedIn: 'root'
})
export class ApiResearchService {
  baseUrl = environment.apiResearchBaseUrl;
  userId: string = null;
  activeResearchId: string = null;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private store: Store<{ 'userProfile': UserProfile }>) {
    this.store.subscribe(userProfile => {
      if (userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
  }

  confirm(tokenId: string): Promise<any> {
    return this.http.post<any>(`${environment.apiBffBaseUrl}research/confirm/${tokenId}`, null).toPromise();
  }
}
