<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  (onOpen)="showTypeForm()"
  customClass="large-modal"
>
  <div class="typeform-popup-wrapper">
    <div id="typeFormContainer">
    </div>
  </div>
</ngx-smart-modal>
