<div class="content-wrapper">
  <router-outlet></router-outlet>
  <loading-modal></loading-modal>
  <premium-popup></premium-popup>
  <premium-expiration-popup></premium-expiration-popup>
  <token-expired-modal></token-expired-modal>
  <health-plaza-reward></health-plaza-reward>
  <medmij-token-expired-modal></medmij-token-expired-modal>
  <typeform-popup></typeform-popup>
</div>
