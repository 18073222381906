import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { OidcService } from '../../../../oidc/oidc.service';
import { AccordionComponent } from '../../accordion/accordion.component';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { ApiBadgeService } from '../../../api/api-badge.service';
import { takeUntil, filter } from 'rxjs/operators';
import { ApiHealthService } from '../../../api/api-health.service';
import { MenuItem } from '../menu.service';
import { FeatureProfileService } from '../../../utils/feature-profile-service';
import { FeatureFlagProfile } from '../../../../shared/models/featureFlagProfile';

@Component({
  selector: 'hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: [ './hamburger-menu.component.scss' ],
})

export class HamburgerMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() toggleHamburgerButtonHtmlId: string;
  @Input() menuOpened: boolean;
  @Output() OnCloseHamburgerMenu = new EventEmitter();
  @Input() admin = false;
  @Input() careGiversDashboard = false;
  @Input() companyLogo = '';
  @Input() companyName = '';
  @Input() atalmedialMenu = false;

  @ViewChildren('accordion') accordions: QueryList<AccordionComponent>;

  // activeSubmenu: null | string = null;
  hamburgerMenuElementRef: null | ElementRef;
  clickOutsideEventHandler: null | (($event: MouseEvent) => void);
  activeMenuItemId: string;

  isWidescreen = false;

  menuItems: MenuItem[];

  showHealthMenuItem = false;

  careGiversDashboardItems = [
    {
      id: 'DASHBOARD',
      icon: '/nav-bottom-menu/icn_dashboard_not_active.svg',
      iconActive: '/nav-bottom-menu/icn_dashboard_active.svg',
      routerLink: '/care-givers-dashboard'
    },
    {
      id: 'DASHBOARD_SELFCARE4ME',
      icon: '/nav-bottom-menu/icn_dashboard_not_active.svg',
      routerLink: '/dashboard/home',
    },
  ];

  adminSmallScreenMenuItems = [
    {
      id: 'EMPLOYEES_AND_CHALLENGES',
      icon: 'icn_flag_orange.svg',
      routerLink: '/admin/employees-challenges',
    },
    {
      id: 'REWARDS',
      icon: 'icn_star_orange.svg',
      routerLink: '/admin/rewards',
    },
  ];

  smallScreenMenuItems = [
    {
      id: 'PROFILE',
      icon: 'icn_profile.svg',
      routerLink: '/dashboard/user-profile'
    },
    {
      id: 'HEALTH_PLAZA',
      icon: 'icn_health_plaza.svg',
      routerLink: '/dashboard/health-plaza'
    },
    {
      id: 'MEASUREMENTS',
      icon: 'icn_measurement.svg',
      routerLink: '/dashboard/measurements'
    },
    // todo only in later version v3!
    {
      id: 'HISTORY',
      icon: '/nav-bottom-menu/icn_history_active.svg',
      routerLink: '/dashboard/history/measurements'
      //     {
      //       id: 'COMPLAINTS_ILLNESSES',
      //       routerLink: '/dashboard/medical/complaints-illnesses'
      //     },
      //     {
      //       id: 'LAB_RESULTS',
      //       routerLink: '/dashboard/medical/lab-results'
      //     },
      //     {
      //       id: 'MEDICATION',
      //       routerLink: '/dashboard/medical/medication'
      //     }
      //     {
      //       id: 'HEALTHCARE_PROVIDERS',
      //       routerLink: '/dashboard/medical/healthcare-providers'
      //     },
      //   ]
      // },
      // {
      //   id: 'PREVENTION',
      //   icon: 'icn_prevention.svg',
      //   subMenu: [
      //     {
      //       id: 'PREVENTION_PLANS',
      //       routerLink: '/dashboard/prevention/prevention-plants'
      //     },
      //   ]
    },
    {
      id: 'KNOWLEDGE_BASE',
      icon: 'icn_education.svg',
      routerLink: '/dashboard/knowledge-base'
    },
    {
      id: 'SETTINGS',
      icon: '/nav-bottom-menu/icn_settings_active.svg',
      routerLink: '/dashboard/settings'
    },
  ];

  largeScreenMenuItems: MenuItem[] = [
    {
      id: 'DASHBOARD',
      icon: '/nav-bottom-menu/icn_dashboard_not_active.svg',
      iconActive: '/nav-bottom-menu/icn_dashboard_active.svg',
      routerLink: '/dashboard/home'
    },
    {
      id: 'NETWORK',
      icon: '/nav-bottom-menu/icn_network_active.svg',
      routerLink: '/dashboard/network'
    },
    {
      id: 'GAMES',
      icon: '/nav-bottom-menu/icn_games_active.svg',
      routerLink: '/dashboard/games'
    },
    {
      id: 'PHR',
      icon: '/nav-bottom-menu/icn_phr_active.svg',
      routerLink: '/dashboard/phr'
    },
    {
      id: 'HISTORY',
      icon: '/nav-bottom-menu/icn_history_active.svg',
      routerLink: '/dashboard/history/measurements'
    },
    {
      id: 'PROFILE',
      icon: 'icn_profile.svg',
      routerLink: '/dashboard/user-profile'
    },
    {
      id: 'HEALTH_PLAZA',
      icon: 'icn_health_plaza.svg',
      routerLink: '/dashboard/health-plaza'
    },
    {
      id: 'MEASUREMENTS',
      icon: 'icn_measurement.svg',
      routerLink: '/dashboard/measurements'
    },
    // todo only in later version v3!
    {
      id: 'MEDICAL',
      icon: 'icn_medical.svg',
      routerLink: '/dashboard/medical/medication'
    //   subMenu: [
    //     {
    //       id: 'COMPLAINTS_ILLNESSES',
    //       routerLink: '/dashboard/medical/complaints-illnesses'
    //     },
    //     {
    //       id: 'LAB_RESULTS',
    //       routerLink: '/dashboard/medical/lab-results'
    //     },
    //     {
    //       id: 'MEDICATION',
    //       routerLink: '/dashboard/medical/medication'
    //     }
    //     {
    //       id: 'HEALTHCARE_PROVIDERS',
    //       routerLink: '/dashboard/medical/healthcare-providers'
    //     },
    //   ]
    },
    // {
    //   id: 'PREVENTION',
    //   icon: 'icn_prevention.svg',
    //   subMenu: [
    //     {
    //       id: 'PREVENTION_PLANS',
    //       routerLink: '/dashboard/prevention/prevention-plants'
    //     },
    //   ]
    // },
    {
      id: 'KNOWLEDGE_BASE',
      icon: 'icn_education.svg',
      routerLink: '/dashboard/knowledge-base'
    },
    {
      id: 'SETTINGS',
      icon: '/nav-bottom-menu/icn_settings_active.svg',
      routerLink: '/dashboard/settings'
    },
  ];

  networkActivity = false;

  showLogout = true;
  private _unsubscribe$ = new Subject();

  constructor(
    hamburgerMenuElementRef: ElementRef,
    private oidcService: OidcService,
    readonly router: Router,
    private readonly apiBadgeService: ApiBadgeService,
    private readonly apiHealthService: ApiHealthService,
    private featureProfileService: FeatureProfileService
  ) {
    this.hamburgerMenuElementRef = hamburgerMenuElementRef;

    combineLatest([
      apiBadgeService.totalIncomingInvites,
      apiBadgeService.totalUnreadMessages,
    ])
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(result => this.networkActivity = result[0] !== 0 || result[1] !== 0);
  }

  @HostListener('window:resize')
  setMenuItems(): void {
    this.isWidescreen = window.innerWidth >= 1000;
    if (!this.admin && !this.careGiversDashboard && (!this.atalmedialMenu || this.oidcService.isAuthenticated())) {
      this.menuItems = this.isWidescreen ? this.largeScreenMenuItems : this.smallScreenMenuItems;
      if (this.showHealthMenuItem) {
        const alreadyAdded = this.menuItems.find(menuItem => menuItem.id === 'DASHBOARD_HEALTH');
        if (!alreadyAdded) {
          this.menuItems.push({
            id: 'DASHBOARD_HEALTH',
            icon: '/nav-bottom-menu/icn_dashboard_not_active.svg',
            routerLink: '/care-givers-dashboard',
          });
        }
      }
    } else if (this.careGiversDashboard) {
      this.menuItems = this.careGiversDashboardItems;
    } else if (!this.atalmedialMenu) {
      this.menuItems = this.isWidescreen ? this.adminSmallScreenMenuItems : this.adminSmallScreenMenuItems;
    }

    this.showLogout = this.oidcService.isAuthenticated() || this.atalmedialMenu;

     // reload after resize
    this.featureProfileService.getFeatureFlagProfile().then(featureFlagProfile => {
      this.setFeatureFlagProfile(featureFlagProfile);
    });
  }

  async ngOnInit(): Promise<void> {
    this.showHealthMenuItem = await this.shouldShowHealthMenuItem();
    this.setActiveMenuItemId();

    this.router.events.subscribe(() => {
      this.setActiveMenuItemId();
    });

    this.featureProfileService.featureProfileChanges()
      .pipe(
        filter(profile => profile !== null),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(profile => {
        this.setMenuItems();
        this.setFeatureFlagProfile(profile);
      });

    await this.featureProfileService.getFeatureFlagProfile();
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuOpened && !changes.menuOpened.firstChange) {
      if (changes.menuOpened.currentValue) {
        this.clickOutsideEventHandler = this.handleClickOutsideMenuEvent.bind(this);
        document.addEventListener('click', this.clickOutsideEventHandler);
      }
    }
  }

  private setFeatureFlagProfile(featureFlagProfile: FeatureFlagProfile) {
    if (!featureFlagProfile || !this.menuItems?.length) return;

    this.showLogout = !featureFlagProfile.hasFeatureFlagDisabled('MENU', 'LOGOUT');

    this.menuItems = this.menuItems.filter(menuItem =>
      !featureFlagProfile.hasFeatureFlagDisabled('MENU', menuItem.id)
    );
  }

  onSubMenuOpened(isOpened: boolean, toggledAccordion: AccordionComponent): void {
    if (isOpened) {
      this.closeAllAccordions(toggledAccordion);
    }
  }

  closeAllAccordions(exception: AccordionComponent = null, closeHamburgerMenu = false): void {
    this.accordions.forEach(accordion => {
      if (accordion === exception) return;
      accordion.closeAccordion();
    });
    if (closeHamburgerMenu) this.closeHamburgerMenu();
  }

  setActiveMenuItemId(): void {
    if (this.menuItems && this.menuItems.length) {
      this.activeMenuItemId = this.menuItems.find(menuItem => {
        if (menuItem.subMenu) return menuItem.subMenu.some(subMenuItem => this.router.url.match(subMenuItem.routerLink));
        return this.router.url.match(menuItem.routerLink);
      })?.id;
    }
  }

  /** Handles closing the menu by clicking 'outside'. Event listener dynamically added/removed based on menu state */
  handleClickOutsideMenuEvent($event: MouseEvent): void {
    const clickedElement = (<HTMLInputElement>$event.target);
    if (
      this.menuOpened &&
      clickedElement.id !== this.toggleHamburgerButtonHtmlId &&
      !this.hamburgerMenuElementRef.nativeElement.contains($event.target)
    ) {
      this.closeHamburgerMenu();
    }
  }

  closeHamburgerMenu(): void {
    if (this.isWidescreen) return;
    document.removeEventListener('click', this.clickOutsideEventHandler);
    this.clickOutsideEventHandler = null;
    this.OnCloseHamburgerMenu.emit(true);
  }

  /** Sets active submenu and accordion + arrow animation */
  toggleSubmenu($event: Event): void {
    const targetElement = <HTMLElement>$event.target;
    const menuItem = targetElement.closest('.menu-item');
    const subMenuContainer = <HTMLElement>menuItem.querySelector('.sub-menu-container');
    if (!subMenuContainer || this.isWidescreen) return;
    const arrow = <HTMLElement>menuItem.querySelector('.expand-arrow');

    if (subMenuContainer.style.height) {
      // closing
      subMenuContainer.style.height = null;
      arrow.style.transform = 'rotate(45deg)';
    } else {
      // opening
      subMenuContainer.style.height = subMenuContainer.scrollHeight + 24 + 'px';
      arrow.style.transform = 'rotate(225deg)';
    }
    // this.activeSubmenu = this.activeSubmenu === id ? null : id;
  }

  async logout(): Promise<void> {
    if (this.atalmedialMenu && !this.oidcService.isAuthenticated()) {
      await this.router.navigateByUrl('');
    } else {
      await this.oidcService.logout();
    }
  }

  async shouldShowHealthMenuItem(): Promise<boolean> {
    try {
      return this.oidcService.isAuthenticated() && await this.apiHealthService.shouldRedirect();
    } catch (e) {
      return false;
    }
  }
}
